import { ReactNode } from 'react';
import { Divider, PageHeader, Space } from 'antd';
import * as React from 'react';
import { TagType } from 'antd/lib/tag';
import { IconType } from 'antd/es/notification';
import generalContent from '../conf/generalContent.json';

export default (props: {
  title?: any;
  description?: string;
  extra?: ReactNode;
  children: ReactNode;
  tags?: React.ReactElement<TagType> | React.ReactElement<TagType>[];
  titleIcon?: React.ReactElement<IconType>;
  headerFooter?: React.ReactElement;
  className?: string;
}) => {
  const { extra = [], title = '', className = '', children, description } = props;

  const linkDocs = (
    <a href={generalContent.general.linkDocumentation} target='_blank' rel='noreferrer'>
      Learn more.
    </a>
  );

  return (
    <>
      <div className={`component-page ${className}`}>
        <PageHeader
          subTitle={`Back`}
          extra={extra}
          onBack={() => window.history.back()}
          tags={props.tags}
          footer={props.headerFooter}
        >
          <h2>
            <Space>
              {props?.titleIcon && props.titleIcon}
              {title}
            </Space>
          </h2>
          {description && (
            <p>
              {description} {linkDocs}
            </p>
          )}
        </PageHeader>
        <Divider style={{ margin: '0px 0 16px' }} />
        <div style={{ padding: '16px' }}>{children}</div>
      </div>
    </>
  );
};
