import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  Tooltip,
  List,
  DatePicker,
  DatePickerProps,
  Switch,
  notification,
  Spin,
  Result,
  Modal,
} from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Page, ResourceSelect, TagList } from 'components';
import { useEffect, useRef, useState } from 'react';
import useInviteCodes, { IInviteCodeItem, IInviteCodeEdit } from 'hooks/useInviteCodes';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import generalContent from '../conf/generalContent.json';
import TextArea from 'antd/lib/input/TextArea';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import RolesSelect from 'components/RolesSelect';
import NotFoundPage from './NotFoundPage';
import { setExpiredDate } from 'utils/utils.js';

export default () => {
  const { pathname } = useLocation();
  const edit = pathname.endsWith('/edit');
  const create = pathname.endsWith('/create');
  const { applicationId = '', invideCodeId = '' } = useParams();
  const screens = useBreakpoint();
  const [formInviteCode] = Form.useForm();
  const [formEditCode] = Form.useForm();
  const [formCreateInviteCode] = Form.useForm();
  const { Title } = Typography;

  // Tooltips
  const { code: codeTooltip } = generalContent.pages.applications;
  const { assignments: assignmentsTooltip, resource: resourceTooltip, role: roleTooltip } = generalContent.general.edit;
  const {
    redeemCode: redeemCodeTooltip,
    description: descriptionTooltip,
    expiration: expirationTooltip,
    usageLimit: usageLimitTooltip,
    numberOfRedemptions: numberOfRedemptionsTooltip,
    emailMatch: emailMatchTooltip,
    active: activeTooltip,
    exhausted: exhaustedTooltip,
    expired: expiredTooltip,
  } = generalContent.pages.inviteCodes;

  const linkDocs = (
    <a href={generalContent.general.linkDocumentation} target='_blank' rel='noreferrer'>
      Learn more.
    </a>
  );

  const navigate = useNavigate();

  const { data, loading, error, getInviteCode, createInvite, updateInvite } = useInviteCodes();
  const [assignments, setAssignments] = useState<string[]>();
  const [assignmentsCreate, setAssignmentsCreate] = useState<string[]>();
  const [resource, setResource] = useState('');
  const [allowedRoleIds, setAllowedRoleIds] = useState<string[]>([]);
  const [role, setRole] = useState({ code: '', id: '' });
  const [status, setStatus] = useState<boolean>();
  const [statusInitial, setStatusInitial] = useState<boolean>();
  const [emailMatchValues, setEmailMatchValues] = useState<boolean>();
  const [visibleSaveModal, setVisibleSaveModal] = useState(false);
  const [modalTxt, setModalTxt] = useState('');
  const formCreateRef = useRef(null);
  const formEditRef = useRef(null);

  const getDataFromISO = (val: string) => {
    return val.substring(0, 10);
  };

  const checkActiveString = () => {
    return statusInitial ? 'active' : 'inactive';
  };

  // Reseting fields if navigation changed
  useEffect(() => {
    setStatus(statusInitial);
    if (formEditRef.current) {
      formEditCode.setFieldsValue({ active: status, status: checkActiveString(), description: data?.description });
    }
  }, [navigate]);

  useEffect(() => {
    if (!create) getInviteCode(applicationId, invideCodeId);
  }, [applicationId]);

  useEffect(() => {
    if (data) {
      // Dates converting
      data['created'] = getDataFromISO(data['created']);
      data['updated'] = getDataFromISO(data['updated']);
      if (data['constraints']['expiration']) {
        data['constraints']['expiration'] = getDataFromISO(data['constraints']['expiration']);
      }

      // Status
      setStatusInitial(data['active'] as boolean);

      // Assignment
      setAssignments(data.awardingAssignments);

      // Expired Date
      if (data?.constraints?.expiration) {
        data['expired'] = setExpiredDate(data.constraints.expiration);
      } else {
        data['expired'] = undefined;
      }

      // Exhausted
      const usage = data.constraints?.usageLimit;
      const redemptions = data.numberOfRedemptions;
      if (usage !== undefined && redemptions !== undefined) {
        data['exhausted'] = redemptions >= usage;
      } else {
        data['exhausted'] = undefined;
      }

      // Passing all data to the Forms
      if (formInviteCode) formInviteCode.setFieldsValue(data);
      if (formEditCode) formEditCode.setFieldsValue(data);
    }
  }, [data]);

  // API Error
  useEffect(() => {
    if (error?.status === 500) {
      setModalTxt('Error 500 - Something is wrong. Please try again later.');
      setVisibleSaveModal(true);
    }
    if (error?.status === 409) {
      const errorTxt = error?.data?.error || 'Invite code already exists.';
      setModalTxt(errorTxt);
      setVisibleSaveModal(true);
    }
  }, [error]);

  // Create form - add Assignments
  useEffect(() => {
    if (create) {
      const assignmentCode = resource ? `${applicationId}.${resource}.${role.code}` : `${applicationId}.${role.code}`;
      if (role.code && formCreateInviteCode) {
        formCreateInviteCode.setFieldValue('result', assignmentCode);
        formCreateInviteCode.setFields([
          {
            name: 'role',
            errors: [],
          },
        ]);
      }
      if (!role.code) formCreateInviteCode.setFieldValue('result', '');
    }
  }, [resource, role.code]);

  useEffect(() => {
    if (formCreateRef.current) formCreateInviteCode.setFieldsValue({ assignmentsCreate });
  }, [assignmentsCreate]);

  // Status
  useEffect(() => {
    setStatus(statusInitial);
    if (formEditRef.current) formEditCode.setFieldValue('status', checkActiveString());
  }, [statusInitial]);

  const onChangeExpirationDate: DatePickerProps['onChange'] = (_, dateString) => {
    if (dateString && formCreateInviteCode)
      formCreateInviteCode.setFieldValue(['constraints', 'expiration'], dateString);
  };

  const onChangeStatus = (checked: boolean) => {
    setStatus(checked);
  };

  const onValuesChange = (_changedValues: any, allValues: any) => {
    const emailMatch = allValues.constraints.emailMatch
      ? create && Object.values(allValues.constraints.emailMatch).filter(Boolean).length > 0
      : false;
    setEmailMatchValues(emailMatch);
  };

  function renderRolesOptions(options: { label: string; value: string }[]) {
    const assignmentsCodes = (assignments || []).map((assignment: any) => assignment.code);
    if (!assignmentsCodes) return options;
    return options.map(({ value, label }) => {
      const disabled =
        assignmentsCodes.indexOf(resource ? `${applicationId}.${resource}.${value}` : `${applicationId}.${value}`) >= 0;
      return {
        value,
        label: disabled ? `${label} (Already Added)` : label,
        disabled,
      };
    });
  }

  const addAssignment = () => {
    if (!role.code) {
      formCreateInviteCode.setFields([
        {
          name: 'role',
          errors: ['Role is required!'],
        },
      ]);
    }

    const newAssignment = formCreateInviteCode.getFieldValue('result');
    const hasAssignment = assignmentsCreate?.some((val) => val === newAssignment);
    if (newAssignment && !hasAssignment) {
      setAssignmentsCreate((prevState) => {
        const hasPrevValues = prevState?.length;
        const newArr = hasPrevValues ? [...prevState, newAssignment] : [newAssignment];
        return newArr;
      });

      // Reset all fields
      formCreateInviteCode.resetFields(['result']);
      formCreateInviteCode.setFieldsValue({ resource: undefined });
      formCreateInviteCode.setFieldsValue({ role: undefined });
      setAllowedRoleIds([]);
      setRole({ code: '', id: '' });
      setResource('');
    }
  };

  const deleteAssignment = (assignment: string) => {
    setAssignmentsCreate((prevState) => {
      return prevState?.filter((item) => item !== assignment);
    });
  };

  const checkReachMaxUsers = () => {
    if (!create) {
      const maxUsers = formEditCode.getFieldValue(['constraints', 'usageLimit']);
      const redemptions = formEditCode.getFieldValue('numberOfRedemptions');
      return maxUsers >= 0 ? redemptions >= maxUsers : false;
    }
  };

  const saveOrUpdateInvite = (val: any) => {
    setStatusInitial(status);

    let expirationDate = undefined;
    let emailMatch: string[] = [];
    if (create) {
      // expirationDate in ISO format
      if (val?.constraints?.expiration) {
        const [YYYY, MM, DD] = val.constraints.expiration.split('-');
        const date = new Date(YYYY, MM - 1, DD);
        expirationDate = date.toISOString();
      }

      const emailMatchValue = val?.constraints?.emailMatch;
      if (emailMatchValue?.length) {
        emailMatch = emailMatchValue?.split(',');
      }
    }

    const finalObjCreate: IInviteCodeItem = {
      awardingAssignments: assignmentsCreate,
      value: val?.value?.toLowerCase(),
      description: val?.description?.trim() || '',
      ['constraints']: {
        ['expiration']: expirationDate,
        ['usageLimit']: val?.constraints?.usageLimit?.length ? val.constraints.usageLimit : undefined,
        ['emailMatch']: emailMatch.length ? emailMatch : undefined,
      },
      active: true,
    };

    const finalObjEdit: IInviteCodeEdit = {
      description: val?.description?.trim() || '',
      active: status,
    };

    const success = (description: string) => {
      notification.success({
        message: 'Success',
        description,
      });
    };

    // Create/Update invite
    if (create) {
      createInvite(applicationId, finalObjCreate, () => {
        success('Invite was successfully created!');
        navigate(`/${applicationId}/invite-codes/`);
      });
    } else {
      updateInvite(applicationId, val?.value?.toLowerCase(), finalObjEdit, () => {
        success('Invite was successfully updated!');
        navigate(`../${invideCodeId}`, { replace: true });
        // updating local variables
        data['description'] = val.description?.trim() || '';
      });
    }
  };

  const closeModal = () => {
    setVisibleSaveModal(false);
  };

  const onResourceChange = (resource: string, allowedRoleIds: string[] = []) => {
    setResource(resource);
    setAllowedRoleIds(allowedRoleIds);
  };

  const onRoleChange = (value: string, roleId: string) => setRole({ code: value, id: roleId });

  const buttons = !loading && (
    <Button type='primary'>
      <Link to={'edit'}>Edit</Link>
    </Button>
  );

  const switchColor = {
    active: { backgroundColor: '#389e0d' },
    inactive: { backgroundImage: 'none', backgroundColor: '#cf1322' },
  };

  // Styles
  const colLeftSize = {
    xs: 24,
    md: edit || create ? 24 : 16,
    lg: edit || create ? 16 : 11,
    xl: edit || create ? 12 : 10,
    xxl: edit ? 16 : create ? 12 : 8,
  };
  const colRightSize = {
    xs: 24,
    md: edit || create ? 24 : 12,
    lg: edit || create ? 8 : 13,
    xl: edit || create ? 8 : 10,
    xxl: edit || create ? 8 : 10,
  };

  const itemsStylesLeft = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: edit || create ? 24 : 8 },
      md: { span: edit || create ? 7 : 18 },
      lg: { span: edit || create ? 8 : 12 },
      xl: { span: edit || create ? 8 : 12 },
      xxl: { span: edit || create ? 6 : 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: edit || create ? 24 : 6 },
      md: { span: edit || create ? 24 : 6 },
      lg: { span: edit || create ? 20 : 24 },
    },
    className: 'txt-bold',
  };

  const itemsStylesRight = {
    labelCol: { xs: { span: 24 }, sm: { span: edit ? 6 : 8 }, md: { span: edit ? 5 : 8 }, lg: { span: 12 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 6 }, lg: { span: 12 } },
    className: 'txt-bold',
  };

  const colAssignmentsStyle = {
    xs: 24,
    md: 12,
  };

  const disabledFieldClass = !create ? 'show-only-field' : '';

  return (
    <Page
      className='inviteCodeEditPage'
      title={edit ? 'Edit Invite Code' : create ? 'Create Invite Code' : 'Invite Code'}
      extra={!loading && !error && !edit && !create && buttons}
    >
      <>
        {loading && !create && !edit && (
          <Row gutter={[24, 0]} style={{ height: '50vh', position: 'relative' }}>
            <Col style={{ width: '100%' }}>
              <Spin style={{ position: 'absolute', left: '50%', top: '40%' }} />{' '}
            </Col>
          </Row>
        )}
        {error && error?.status === 404 && <NotFoundPage {...error.data} />}
        {error && !(error?.status === 404) && !(error?.status === 500) && <Result title={error?.data?.error || ''} />}
        {((!loading && !error) || create || error?.status === 500) && (
          <Row gutter={[24, 0]}>
            <Col {...colLeftSize}>
              <Form
                key={create ? 'formCreateInviteCodeKey' : 'formEditCodeKey'}
                name={create ? 'formCreateInviteCode' : 'formEditCode'}
                form={create ? formCreateInviteCode : formEditCode}
                onFinish={saveOrUpdateInvite}
                onValuesChange={onValuesChange}
                data-name={create ? 'formCreateInviteCode' : 'formEditCode'}
                ref={create ? formCreateRef : formEditRef}
              >
                {/* Redeem Code */}
                <Form.Item
                  {...itemsStylesLeft}
                  name='value'
                  label='Redeem Code'
                  rules={[
                    {
                      required: create,
                      pattern: new RegExp(/^[A-Za-z0-9]{3,50}$/g),
                      message:
                        'Redeem Code required. It must be 3-50 characters long. Only letters (A-Z) or numbers (0-9) are accepted.',
                    },
                  ]}
                  validateTrigger={['onChange', 'onBlur']}
                  tooltip={{
                    title: (
                      <>
                        {redeemCodeTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <Input
                    disabled={!create}
                    className={`${disabledFieldClass} uppercase`}
                    onKeyPress={(event) => {
                      const regex = /^([A-Za-z0-9])$/g;
                      if (!regex.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>

                {/* Description */}
                <Form.Item
                  {...itemsStylesLeft}
                  name='description'
                  label='Description'
                  validateTrigger={['onChange', 'onBlur']}
                  tooltip={{
                    title: (
                      <>
                        {descriptionTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <TextArea
                    disabled={!create && !edit}
                    className={disabledFieldClass}
                    autoSize={{ minRows: 1, maxRows: 4 }}
                  />
                </Form.Item>

                <Divider
                  type='horizontal'
                  style={{
                    display: `${create ? 'block' : 'none'}`,
                    margin: '35px 0 25px',
                    borderColor: 'grey',
                    opacity: '0.4',
                  }}
                />

                <Form.Item {...itemsStylesLeft} label='Invite Code Restrictions'></Form.Item>

                {/* Expiration Date */}
                <Form.Item
                  {...itemsStylesLeft}
                  name={['constraints', 'expiration']}
                  label='Expiration Date'
                  tooltip={{
                    title: (
                      <>
                        {expirationTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  style={{ display: `${!create ? 'block' : 'none'}` }}
                  shouldUpdate
                >
                  <Input hidden={create} disabled className={disabledFieldClass} placeholder='-' />
                </Form.Item>

                {/* Expiration Date Picker */}
                <Form.Item
                  {...itemsStylesLeft}
                  name='expirationPicker'
                  label='Expiration Date'
                  tooltip={{
                    title: (
                      <>
                        {expirationTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  style={{ display: `${create ? 'block' : 'none'}` }}
                  shouldUpdate
                >
                  <DatePicker onChange={onChangeExpirationDate} style={{ width: '100%' }} />
                </Form.Item>

                {/* Max Number of Users */}
                <Form.Item
                  {...itemsStylesLeft}
                  name={['constraints', 'usageLimit']}
                  label='Max Number of Users'
                  tooltip={{
                    title: (
                      <>
                        {usageLimitTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <Input
                    disabled={!create}
                    className={disabledFieldClass}
                    placeholder={!create ? '-' : ''}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>

                {/* Number of Redemptions */}
                <Form.Item
                  hidden={create}
                  {...itemsStylesLeft}
                  name='numberOfRedemptions'
                  label='Number of Redemptions'
                  tooltip={{
                    title: (
                      <>
                        {numberOfRedemptionsTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <Input
                    type='number'
                    disabled={!create}
                    className={disabledFieldClass}
                    style={{ color: checkReachMaxUsers() ? 'red' : 'black' }}
                  />
                </Form.Item>

                {/* Email String Match */}
                <Form.Item
                  {...itemsStylesLeft}
                  name={['constraints', 'emailMatch']}
                  label='Email String Match'
                  rules={[
                    {
                      required: emailMatchValues,
                      pattern: emailMatchValues
                        ? new RegExp(/^[a-zA-Z0-9!.#$%&'*+-/=?^_`{|}~@]+$/g)
                        : new RegExp(/(.*?)/g),
                      message: "It must have one of the following chars: a-zA-Z0-9!.#$%&'*+-/=?^_`{|}~@",
                    },
                  ]}
                  validateTrigger={['onChange', 'onBlur']}
                  tooltip={{
                    title: (
                      <>
                        {emailMatchTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <TextArea
                    disabled={!create}
                    className={disabledFieldClass}
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    placeholder={create ? 'ceo@dolby.com,@valtech,apple' : '-'}
                    onKeyPress={(event) => {
                      if (/\s/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>

                {/* Status */}
                <Form.Item
                  hidden={create || edit}
                  {...itemsStylesLeft}
                  name='status'
                  label='Status'
                  tooltip={{
                    title: (
                      <>
                        {activeTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <Input disabled={!create && !edit} className={disabledFieldClass} />
                </Form.Item>

                {/* Status Edit*/}
                <Form.Item
                  hidden={!edit}
                  {...itemsStylesLeft}
                  name='active'
                  label='Status'
                  tooltip={{
                    title: (
                      <>
                        {activeTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <Switch
                    checkedChildren='ON'
                    unCheckedChildren='OFF'
                    checked={status}
                    onChange={onChangeStatus}
                    style={status ? switchColor.active : switchColor.inactive}
                  />
                </Form.Item>

                {/* Expired */}
                <Form.Item
                  hidden={create}
                  {...itemsStylesLeft}
                  name='expired'
                  label='Expired'
                  tooltip={{
                    title: (
                      <>
                        {expiredTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                >
                  <Input disabled={!create} className={disabledFieldClass} placeholder='-' />
                </Form.Item>

                {/* Exhausted */}
                <Form.Item
                  hidden={create}
                  {...itemsStylesLeft}
                  name='exhausted'
                  label='Exhausted'
                  tooltip={{
                    title: (
                      <>
                        {exhaustedTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                >
                  <Input disabled={!create} className={disabledFieldClass} placeholder='-' />
                </Form.Item>

                {/* Assignments */}
                <Form.Item
                  {...itemsStylesLeft}
                  label='Assignments'
                  name='awardingAssignments'
                  hidden={create}
                  shouldUpdate
                >
                  {assignments?.length ? (
                    <TagList
                      tags={assignments}
                      target={edit ? '../../assignments/' : '../assignments/'}
                      styleProp={{ fontWeight: 'normal', wordBreak: 'break-all' }}
                    />
                  ) : (
                    <span className='txt-normal'> -</span>
                  )}
                </Form.Item>

                <Divider
                  type='horizontal'
                  style={{
                    display: `${create ? 'block' : 'none'}`,
                    margin: '35px 0',
                    borderColor: 'grey',
                    opacity: '0.4',
                  }}
                />

                {/* CREATE ASSIGNMENTS */}
                <Row gutter={[24, 0]}>
                  <Col {...colAssignmentsStyle} hidden={!create}>
                    <Title level={5}>Add Assignments:</Title>
                    <Form.Item
                      label='Resource'
                      name='resource'
                      tooltip={{
                        title: (
                          <>
                            {resourceTooltip} {linkDocs}
                          </>
                        ),
                        overlayInnerStyle: { textAlign: 'center' },
                      }}
                      shouldUpdate
                    >
                      <ResourceSelect
                        onChange={onResourceChange}
                        applicationId={applicationId}
                        selectedRole={role.id}
                        valueIndex={'code'}
                      />
                    </Form.Item>
                    <Form.Item
                      name='role'
                      label='Roles'
                      tooltip={{
                        title: (
                          <>
                            {roleTooltip} {linkDocs}
                          </>
                        ),
                        overlayInnerStyle: { textAlign: 'center' },
                      }}
                      shouldUpdate
                    >
                      <RolesSelect
                        allowedRoleIds={allowedRoleIds}
                        onChange={onRoleChange}
                        valueIndex={'code'}
                        applicationId={applicationId}
                        renderOptions={renderRolesOptions}
                      />
                    </Form.Item>
                    <Form.Item shouldUpdate style={{ float: 'right' }}>
                      <Button type='primary' hidden={!create} onClick={() => addAssignment()}>
                        Add
                      </Button>
                    </Form.Item>
                    <Form.Item name='result'>
                      <Input hidden />
                    </Form.Item>
                  </Col>
                  <Col {...colAssignmentsStyle} hidden={!create}>
                    <Title level={5}>
                      <span className='requiredField'>Assignments </span>
                      <Tooltip
                        title={
                          <>
                            {assignmentsTooltip} {linkDocs}
                          </>
                        }
                        overlayInnerStyle={{ textAlign: 'center' }}
                      >
                        <QuestionCircleOutlined
                          style={{ fontSize: '14px', color: 'rgba(0,0,0,.45)', cursor: 'help', marginRight: '2px' }}
                        />
                      </Tooltip>
                      :
                    </Title>
                    <List bordered style={{ width: '100%', minHeight: '48px' }}>
                      {' '}
                      {(assignmentsCreate || []).length ? (
                        assignmentsCreate?.map((assignment: any, index: number) => (
                          <List.Item
                            key={`${assignment}-${index}`}
                            style={{ wordBreak: 'break-all' }}
                            extra={
                              <DeleteOutlined
                                onClick={() => deleteAssignment(assignment)}
                                style={{ paddingRight: '20px' }}
                              />
                            }
                          >
                            {assignment}
                          </List.Item>
                        ))
                      ) : (
                        <List.Item>No Assignment</List.Item>
                      )}{' '}
                    </List>
                    <Form.Item
                      name='assignmentsCreate'
                      hidden={!create}
                      className='assigmentsList'
                      shouldUpdate
                      rules={[
                        {
                          required: create,
                          message: 'Assignment is required!',
                        },
                      ]}
                      validateTrigger={['onChange']}
                    >
                      <Input hidden />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  type='horizontal'
                  style={{
                    display: `${create ? 'block' : 'none'}`,
                    margin: '20px 0',
                    borderColor: 'grey',
                    opacity: '0.4',
                  }}
                />

                {/* Create Invite CTA */}
                <Row hidden={!create && !edit} gutter={[8, 0]}>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Form.Item style={{ marginTop: '10px' }} shouldUpdate>
                      <Button type='primary' htmlType='submit'>
                        {create ? 'Add Invite' : 'Update Invite'}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col {...colRightSize}>
              <Divider
                type='horizontal'
                style={{
                  display: `${edit && !screens.lg ? 'block' : 'none'}`,
                  margin: '25px 0',
                  borderColor: 'grey',
                  opacity: '0.4',
                }}
              />
              <Form key={'noEdit'} name={'noEdit'} form={formInviteCode}>
                {/* App Code */}
                <Form.Item
                  {...itemsStylesRight}
                  label='App Code'
                  name='code'
                  tooltip={{
                    title: (
                      <>
                        {codeTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <span className='txt-normal'>{applicationId}</span>
                </Form.Item>

                {/* Created Date */}
                <Form.Item {...itemsStylesRight} hidden={create} label='Created' name='created' shouldUpdate>
                  <Input disabled className={disabledFieldClass} />
                </Form.Item>

                {/* Updated Date */}
                <Form.Item {...itemsStylesRight} hidden={create} label='Updated' name='updated' shouldUpdate>
                  <Input disabled className={disabledFieldClass} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
        <Modal
          title='Alert'
          visible={visibleSaveModal}
          onOk={closeModal}
          okText='Ok'
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <p>{modalTxt}</p>
        </Modal>
      </>
    </Page>
  );
};
