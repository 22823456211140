import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Avatar, Menu } from 'antd';
import { useOktaAuth } from '@okta/okta-react';
import { UserClaims } from '@okta/okta-auth-js';

export default (props: { userData?: UserClaims }) => {
  const { oktaAuth } = useOktaAuth();
  const logoutOkta = async () => {
    await oktaAuth.signOut();
  };

  const { userData } = props;

  const menuItems = [
    {
      key: 'name',
      label: (
        <div className='avatarMenu_infos'>
          {userData?.name && (
            <span className='avatarMenu_name' title={userData?.name}>
              {userData?.name}
            </span>
          )}
          {userData?.email && (
            <span className='avatarMenu_email'>
              <a href={`mailto:${userData?.email}`}>{userData?.email}</a>
            </span>
          )}
          {(userData?.name || userData?.email) && <hr className='avatarMenu_separator' />}
        </div>
      ),
      className: `avatarMenu_item avatarMenu_noHover ${!userData?.name && !userData?.email ? '-hide' : false}`,
    },
    {
      key: 'logout',
      icon: <LogoutOutlined style={{ fontSize: '18px', color: 'var(--color-primary-light)' }} />,
      label: (
        <>
          <a onClick={logoutOkta} rel='noopener noreferrer' className='avatarMenu_logout'>
            Logout
          </a>
        </>
      ),
      className: 'avatarMenu_item',
    },
  ];
  const items = <Menu items={menuItems} />;

  return (
    <>
      <Dropdown
        className='avatarMenu'
        overlayClassName='avatarMenu_wrapper'
        overlay={items}
        trigger={['click']}
        placement='bottomRight'
      >
        <div onClick={(e) => e.preventDefault()}>
          <Avatar size='default' icon={<UserOutlined />} />
        </div>
      </Dropdown>
    </>
  );
};
