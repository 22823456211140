import { Space, Table } from 'antd';
import Column from 'antd/es/table/Column';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import AssignmentsTagList from './AssignmentsTagList';
import { useEffect, useState } from 'react';
import PaginationGeneric from './PaginationGeneric';
import useLocalStorage from 'hooks/useLocalStorage';

export default (props: { clients: any; total: number; loading: boolean }) => {
  const { clients, total, loading } = props;
  const [lsPageSize] = useLocalStorage<number>('am-page-size', 10);
  const [clientsArr, setClientsArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeInner, setPageSizeInner] = useState(lsPageSize);

  useEffect(() => {
    setClientsArr(clients);
  }, [clients]);

  const pageChange = (currentPage: number, pageSize: number) => {
    setCurrentPage(currentPage);
    setPageSizeInner(pageSize);
  };

  // Amount of data to pass to Table
  const calcPage = (currentPage - 1) * pageSizeInner;
  let clientsToShow = clientsArr?.slice(calcPage, calcPage + pageSizeInner);
  useEffect(() => {
    if (clientsArr && clientsArr.length > pageSizeInner) {
      clientsToShow = clientsArr?.slice(calcPage, calcPage + pageSizeInner);
      if (!clientsToShow?.length) {
        pageChange(1, pageSizeInner);
      }
    } else {
      clientsToShow = clientsArr;
      if (clientsToShow?.length) setCurrentPage(1);
    }
  }, [clientsToShow]);

  const renderActions = (client: any) => (
    <Space size='middle'>
      <Link to={`${client.id}/edit`}>
        <EditOutlined style={{ fontSize: '18px' }} />
      </Link>
    </Space>
  );

  return (
    <>
      <Table rowKey='id' dataSource={clientsToShow} pagination={false} loading={props.loading}>
        <Column title='Name' render={({ id, label }) => <Link to={id}>{label}</Link>} />
        <Column title='Assignments' render={(client) => <AssignmentsTagList user={client} />} />
        <Column title='Actions' key='client-table-col-actions' align='center' render={renderActions} />
      </Table>

      <PaginationGeneric
        loading={loading}
        total={total}
        hideOnSinglePage={true}
        showSizeChanger={true}
        onPageChange={(page, pageSize) => {
          pageChange(page, pageSize);
        }}
      ></PaginationGeneric>
    </>
  );
};
