import { Result } from 'antd';
import { Link } from 'react-router-dom';

export default (props: { status?: any; error?: string }) => (
  <Result
    status={props.status || 404}
    title={props.error || 'Sorry, the page you visited does not exist.'}
    extra={
      <Link to={'/'} type='primary'>
        Home
      </Link>
    }
  />
);
