import { Tag } from 'antd';

export default (props: {
  tags: string[];
  tagColor?: string;
  target?: string;
  targetComplement?: string;
  styleProp?: object;
}) => {
  const { tags, tagColor, target, targetComplement, styleProp } = props;

  return (
    <>
      {tags.map((tag, i) => (
        <a href={`./${target ?? 'assignments/'}${tag}${targetComplement ?? '/users'}`} key={`${tag}-${i}`}>
          <Tag color={tagColor || `geekblue`} style={{ margin: '5px', ...styleProp }} key={`${tag}-${i}`}>
            {tag}
          </Tag>
        </a>
      ))}
    </>
  );
};
