import { Pagination, Select } from 'antd';
import useLocalStorage from 'hooks/useLocalStorage';
import { useEffect, useState } from 'react';

export default (props: {
  loading?: boolean;
  total?: number;
  current?: number | null;
  pageSizeInitial?: number;
  position?: string;
  simple?: boolean;
  hideOnSinglePage?: boolean;
  showSizeChanger?: boolean;
  resetPagination?: boolean;
  onPageChange: (page: number, pageSize: number) => void;
  showSizeChangerOnSimple?: boolean;
  onPageSizeChange?: (currentPage: number, pageSize: number) => void;
  onLoad?: (settings: { pageSizeInner: number }) => void;
}) => {
  const {
    onPageChange,
    onPageSizeChange,
    showSizeChangerOnSimple = false,
    loading = false,
    current: parentCurrent,
    resetPagination = false,
    onLoad,
  } = props;

  const [lsPageSize, setLsPageSize] = useLocalStorage<number>('am-page-size', 10);
  const [current, setCurrent] = useState(1);
  const [pageSizeInner, setPageSizeInner] = useState<number>(lsPageSize);
  const [showSizeChanger, setShowSizeChanger] = useState(false);

  const optionsPagination = [
    {
      value: 10,
      label: '10 / page',
    },
    {
      value: 20,
      label: '20 / page',
    },
    {
      value: 50,
      label: '50 / page',
    },
    {
      value: 100,
      label: '100 / page',
    },
  ];

  useEffect(() => {
    const navigationPage = document.querySelector('.ant-pagination-prev');
    if (navigationPage) {
      setShowSizeChanger(true);
    } else {
      setShowSizeChanger(false);
      setCurrent(1);
    }
  });

  useEffect(() => {
    if (typeof onLoad === 'function') onLoad({ pageSizeInner: pageSizeInner });
  }, [pageSizeInner]);

  useEffect(() => {
    if (resetPagination) setCurrent(1);
  }, [resetPagination]);

  const pageChange = (page: number, pageSize: number) => {
    setCurrent(page);
    setPageSizeInner(pageSize);
    if (typeof onPageChange === 'function') onPageChange(page, pageSize);
  };

  const sizeChange = (current: number, pageSize: number) => {
    setPageSizeInner(pageSize);
    setLsPageSize(pageSize);
    if (typeof onPageSizeChange === 'function') onPageSizeChange(current, pageSize);
  };

  return (
    <>
      <div
        className={`custom-simple-pagination ant-pagination ant-table-pagination ant-table-pagination-${
          props.position || 'center'
        }`}
      >
        <Pagination
          total={props.total || 0}
          current={parentCurrent ?? current}
          pageSize={pageSizeInner}
          simple={props.simple || false}
          hideOnSinglePage={props.hideOnSinglePage}
          showSizeChanger={props.showSizeChanger || false}
          onChange={pageChange}
          onShowSizeChange={sizeChange}
        />
        <span>
          {showSizeChangerOnSimple && showSizeChanger && (!loading || current > 1) && (
            <Select
              defaultValue={pageSizeInner}
              style={{}}
              onChange={(val) => {
                sizeChange(current, val);
              }}
              options={optionsPagination}
            />
          )}
        </span>
      </div>
    </>
  );
};
