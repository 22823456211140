import { Select } from 'antd';

const { Option } = Select;

const children = ['Admin', 'Editor', 'Viewer', 'SuperAdmin', 'User'].map((option) => (
  <Option key={option}>{option}</Option>
));

export default (props: { onChange: (items: string[]) => void }) => (
  <>
    <div style={{ marginRight: '10px' }} />
    <Select
      mode='multiple'
      allowClear
      style={{ width: '100%' }}
      placeholder='Select Roles'
      onChange={(value: string[]) => props.onChange(value)}
    >
      {children}
    </Select>
  </>
);
