import { AuthState, OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from '../conf';
import { useNavigate } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { ReactElement } from 'react';
import { Button, Typography } from 'antd';

const transformAuthState = async (oktaAuth: OktaAuth, authState: AuthState) => {
  const isAuthenticated = !!oktaAuth.getAccessToken();
  const { requiredAssignment } = config.authManager;
  const isAuthorized =
    [...((authState as any)?.accessToken?.claims?.assignments || [])].indexOf(requiredAssignment) >= 0;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const message = isAuthenticated && !isAuthorized ? unnauthorizedMessage : 'Redirecting to login';

  return {
    ...authState,
    isAuthenticated,
    isAuthorized,
    message,
  };
};

const oktaAuth = new OktaAuth({
  ...config.okta,
  redirectUri: `${window.location.origin}/implicit/callback`,
  postLogoutRedirectUri: `${window.location.origin}/implicit/logout`,
  transformAuthState,
});

export const unnauthorizedMessage = (
  <>
    <Typography.Text>
      <h3 style={{ color: '#a52626' }}>You are not authorized to access this app.</h3>
      <h5>Please contact the IT Enterprise Marketing Webnet team for support.</h5>
    </Typography.Text>
    <Button
      onClick={async () => await oktaAuth.signOut()}
      style={{
        background: '#001529',
        color: '#ffffff',
      }}
    >
      Logout
    </Button>
  </>
);

export default (props: { children: ReactElement | null }) => {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {props.children}
    </Security>
  );
};
