import { Button, notification, Result, Space } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Page, ResourceTable } from '../components';
import { useEffect, useState } from 'react';
import useResources, { IResource } from '../hooks/useResources';
import { ProjectOutlined } from '@ant-design/icons';
import useLocalStorage from 'hooks/useLocalStorage';
import generalContent from '../conf/generalContent.json';
import usePermissions from 'hooks/usePermissions';
import Typography from 'antd/lib/typography';

const { Text, Paragraph } = Typography;

export default () => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const [resources, setResources] = useState<IResource[]>([]);
  const [total, setTotal] = useState(0);
  const { data, loading, getAllWithLimit, removeResource, error } = useResources();
  const { checkPermissions } = usePermissions();
  const [lsPageSize] = useLocalStorage<number>('am-page-size', 10);
  const navigate = useNavigate();

  const onDelete = async (resource: IResource) => {
    return await removeResource(applicationId, resource?.id, (resp: any) => {
      if (resp?.name !== 'AxiosError') {
        notification.success({
          message: 'Success',
          description: `Resource ${resource?.name} was successfully deleted`,
        });

        if (data) data.items = (data.items as IResource[]).filter((val) => val.id !== resource?.id);
      }
    });
  };

  const onPageChange = (val: number, pageSize: number) => {
    getAllWithLimit(applicationId || '', pageSize, (val - 1) * pageSize);
  };

  useEffect(() => {
    if (error?.data) {
      const key = `error-notification`;
      const btn = error.data.assignments && (
        <Button danger onClick={() => notification.close(key)}>
          Close
        </Button>
      );

      notification.error({
        message: 'Attention',
        key,
        description: (
          <div>
            <Text>{`${error?.data?.error || 'Error occur during the operation'}`}</Text>
            <br />
            <br />
            {error?.data?.assignments && <Text strong>Assignments:</Text>}
            {error?.data?.assignments && (
              <Paragraph>
                <ul>
                  {((error?.data?.assignments as any) || []).map((assignment: string) => (
                    <li key={`li-item-${assignment}`}>
                      <Typography.Link onClick={() => navigate(`/${applicationId}/assignments/${assignment}/users`)}>
                        {assignment}
                      </Typography.Link>
                    </li>
                  ))}{' '}
                </ul>
              </Paragraph>
            )}
            <Space></Space>
          </div>
        ),
        btn,
        duration: !error.data.assignments ? null : 5,
      });
    }
  }, [error]);

  useEffect(() => {
    getAllWithLimit(applicationId || '', lsPageSize);
    setResources(data?.items || []);
  }, [applicationId]);

  useEffect(() => {
    if (data?.items && data?.total) {
      setResources(data.items || []);
      setTotal(data.total);
    }
  }, [data, data?.items]);

  const addResourceCta = checkPermissions(applicationId, 'resources:create') && (
    <Button key='add-resource' type='primary'>
      <Link to={'create'}>Add Resource</Link>
    </Button>
  );

  const extraElements = [addResourceCta];
  return (
    <Page
      title='Resources'
      extra={!error && extraElements}
      titleIcon={<ProjectOutlined />}
      description={generalContent.pages.resources.pageDescription}
    >
      {error && error.status !== 428 ? (
        <Result title={error?.data?.error || ''} />
      ) : (
        <ResourceTable
          onPageChange={onPageChange}
          total={total}
          onDelete={onDelete}
          resources={resources}
          loading={loading}
          error={error}
        />
      )}
    </Page>
  );
};
