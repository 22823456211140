import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Tag } from 'antd';

export default () => {
  const screens = useBreakpoint();

  return (
    <div style={{ marginLeft: '-10px' }}>
      {Object.entries(screens)
        .filter((screen) => !!screen[1])
        .map((screen) => (
          <Tag color='blue' key={screen[0]}>
            {screen[0]}
          </Tag>
        ))}
    </div>
  );
};
