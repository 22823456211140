import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AssignmentsMembersTable, Page, SearchUser } from '../components';
import { Alert, Col, Descriptions, Modal, notification, Row, Tabs, Typography } from 'antd';
import { UserValue } from '../components/SearchUser';
import useUsers, { IUser } from '../hooks/useUsers';
import useApplications from '../hooks/useApplications';
import usePermissions from 'hooks/usePermissions';
// import { WarningOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { TabPane } = Tabs;

export default (props: { type?: string }) => {
  const { isItOktaAssignmentMapped, getSelectedApplication } = useApplications();
  const { applicationId = '', assignmentId = '' } = useParams<{ applicationId: string; assignmentId?: string }>();
  const {
    data: usersList = [],
    removeAssignment,
    addUsersToAssignment,
    getAssignmentsUsersWithPagination,
  } = useUsers();
  const [userType, setUserType] = useState(props.type || 'users');
  const application = getSelectedApplication();
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [user, setUser] = useState<IUser | any>({});
  const [loading, setLoading] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [pageSizeInner, setPageSizeInner] = useState<number>(0);
  const { pathname } = useLocation();
  const { checkPermissions } = usePermissions();
  const navigate = useNavigate();

  const redirectToUserType = () => {
    const [_, page, assignment] = pathname.substring(1).split('/');
    navigate(`/${applicationId}/${page}/${assignment}/${userType || 'users'}`);
  };

  const loadUsers = () => {
    if (blocked || pageSizeInner === 0) return;
    setLoading(true);
    setUser([]);
    getAssignmentsUsersWithPagination(applicationId, assignmentId, userType, pageSizeInner, 0).then(
      (userResponse: any) => {
        const { items = [], cursor = { self: 0, next: 0, total: 0 } } = userResponse.data;
        setUsers(items);
        setTotal(cursor.total);
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    if (pageSizeInner > 0) loadUsers();
  }, [pageSizeInner]);

  const onDelete = (user: IUser): void => {
    setVisibleDeleteModal(true);
    setUser(user);
  };

  useEffect(() => {
    if (applicationId && assignmentId && isItOktaAssignmentMapped(assignmentId)) {
      setBlocked(true);
    }
  }, [applicationId, assignmentId]);

  const onPageChange = (pageNumber: number, pageSize: number) => {
    getAssignmentsUsersWithPagination(
      applicationId || '',
      assignmentId,
      userType,
      pageSize,
      (pageNumber - 1) * pageSize,
    );
  };

  const onPaginationLoad = (settings: { pageSizeInner: number }) => {
    setPageSizeInner(settings.pageSizeInner);
  };

  const onAssign = (users: UserValue[], setValue: (users: UserValue[]) => void) => {
    addUsersToAssignment(
      applicationId,
      users.map((user: UserValue) => user.value),
      assignmentId,
      userType,
    ).then((results: any) => {
      results.map((result: any) => {
        const getUserLabel = (id: string) => users.filter((user) => user.value === id)[0].label;
        const id = (result.value || result.reason).config?.url.split('/')[4];
        if (result.status === 'fulfilled') {
          notification.success({
            message: 'Success',
            description: `The user "${getUserLabel(id)}" was successfully assigned to "${assignmentId}"`,
          });
        } else {
          notification.error({
            message: 'Error',
            description: `Error during assign the user "${getUserLabel(id)}" to assignment code "${assignmentId}"`,
          });
        }
      });
      setValue([]);
      loadUsers();
    });
  };

  const handleRemoveAssignment = async () => {
    setDeleteModalLoading(true);
    await removeAssignment(applicationId, user.id, assignmentId).then(() => {
      setVisibleDeleteModal(false);
      setDeleteModalLoading(false);
      loadUsers();
    });
  };

  const renderOptions = (user: any) => ({
    label:
      user?.label ||
      [
        user?.profile?.firstName,
        user?.profile?.lastName,
        `<${user?.profile?.email}>`,
        (user?.assignments || []).indexOf(assignmentId) >= 0 ? '(Added)' : '',
      ].join(' '),
    value: user?.id,
    disabled: (user?.assignments || []).indexOf(assignmentId) >= 0,
  });

  const renderFooter = () => {
    const slot = {
      right: checkPermissions(applicationId, 'assignments:create') && (
        <SearchUser applicationId={applicationId} onAssign={onAssign} userType={userType} mapOption={renderOptions} />
      ),
    };
    return (
      <Row>
        <Col span={24}>
          <Descriptions size='small' column={1}>
            <Descriptions.Item label='Application' labelStyle={{ fontWeight: 'bold' }}>
              {application?.name || ''}
            </Descriptions.Item>
            <Descriptions.Item label='Assignment code' labelStyle={{ fontWeight: 'bold' }}>
              {assignmentId || ''}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Tabs
          hidden={blocked}
          defaultActiveKey='1'
          style={{ width: '100%' }}
          activeKey={userType}
          onChange={setUserType}
          tabBarExtraContent={slot}
        >
          <TabPane tab='Users' key='users' />
          <TabPane tab='Clients' key='clients' />
        </Tabs>
      </Row>
    );
  };

  useEffect(() => {
    if (userType) {
      if (userType && !pathname.toString().endsWith(`/${userType}`)) {
        redirectToUserType();
      }
      loadUsers();
    } else {
      redirectToUserType();
    }
  }, [userType, assignmentId]);

  useEffect(() => {
    if ((usersList || []).length === 0) {
      setLoading(true);
    } else {
      setUsers(usersList?.items);
    }
  }, [usersList]);

  return (
    <Page title={`Assignment: ${assignmentId}`} headerFooter={renderFooter()}>
      {blocked ? (
        <Col span={24}>
          <Alert message={'This assignment role need to be managed by Okta.'} type='warning' showIcon />
        </Col>
      ) : (
        <AssignmentsMembersTable
          users={users}
          total={total}
          userType={userType}
          onDelete={onDelete}
          hideAssignments
          loading={loading}
          onPageChange={onPageChange}
          onPaginationLoad={onPaginationLoad}
        />
      )}
      <Modal
        title='Alert'
        visible={visibleDeleteModal}
        onOk={handleRemoveAssignment}
        okButtonProps={{ danger: true, disabled: deleteModalLoading }}
        okText={deleteModalLoading ? 'Removing' : 'Remove'}
        cancelButtonProps={{ disabled: deleteModalLoading }}
        confirmLoading={deleteModalLoading}
        onCancel={() => setVisibleDeleteModal(false)}
      >
        <div style={{ textAlign: 'center', padding: '10px' }}>
          <h3>Do you want to remove the {userType === 'users' ? 'user' : 'client'} from assignment?</h3>
        </div>
        <Descriptions size='small' column={1}>
          <Descriptions.Item
            label={(userType === 'users' ? 'User' : 'Client') || '???'}
            labelStyle={{ fontWeight: 'bold' }}
          >
            <Text code>{userType === 'users' ? user?.profile?.email : user?.label}</Text>
          </Descriptions.Item>
          <Descriptions.Item label='Assignment' labelStyle={{ fontWeight: 'bold' }}>
            <Text code>{assignmentId || ''}</Text>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </Page>
  );
};
