import useAxios from './useAxios';
import { useState } from 'react';
export interface IResource {
  applicationId?: string;
  applicationCode?: string;
  id: string;
  name: string;
  parentResourceId?: string;
  parentResource?: {
    id?: string;
    name?: string;
    description?: string;
    code?: string;
  };
  description: string;
  code: string;
  allowedRoleIds: string[];
}

export default (): {
  data: any;
  error: any;
  loading: boolean;
  loadingRecursive: boolean;
  getAll: (applicationId: string) => Promise<any>;
  getAllWithLimit: (applicationId: string, limit: number, offset?: number) => Promise<any>;
  getCompleteData: (applicationId: string) => Promise<any>;
  getResource: (applicationId: string, resourceId: string) => void;
  updateResource: (
    applicationId: string,
    resourceId: string,
    resource: IResource,
    callback: (data: IResource) => void,
  ) => Promise<any>;
  removeResource: (
    applicationId: string,
    resourceId: string,
    callback: (data: IResource) => void,
  ) => Promise<IResource>;
  createResource: (applicationId: string, resource: IResource, callback: (data: IResource) => void) => Promise<any>;
} => {
  const { data, loading, error, request, requestAsync } = useAxios();

  const [loadingRecursive, setLoadingRecursive] = useState(false);

  const getAll = async (applicationId: string): Promise<IResource[]> => {
    return await requestAsync(`/applications/${applicationId}/resources`).then(
      (response) => response?.data?.items || [],
    );
  };

  const getAllWithLimit = async (applicationId: string, limit: number, offset?: number): Promise<any> => {
    return requestAsync(`/applications/${applicationId}/resources?limit=${limit}&offset=${offset || 0}`).then(
      (payload) => payload?.data?.items || [],
    );
  };

  const getCompleteData = async (applicationId: string): Promise<any> => {
    let offset = 0;
    let total = 0;
    const items: any[] = [];
    setLoadingRecursive(true);

    const urlData = async () => {
      const url = `/applications/${applicationId}/resources?limit=${200}&offset=${offset}`;
      try {
        await requestAsync(url).then((payload) => {
          if (payload?.data?.items.length) {
            total = payload?.data?.total;
            items.push(...payload.data.items);
            if (total > items.length) {
              offset += 200;
              urlData();
            } else {
              setLoadingRecursive(false);
            }
          } else {
            setLoadingRecursive(false);
          }
        });
      } catch (e) {
        console.log('e=>', e);
        setLoadingRecursive(false);
      }
    };
    urlData();
  };

  const getResource = (applicationId: string, resourceId: string): void => {
    request(`/applications/${applicationId}/resources/${resourceId}`);
  };

  const mapParentResourceId = (resource: IResource): IResource => {
    resource = { ...resource, parentResourceId: resource?.parentResource?.id || '' };
    delete (resource as any).parentResource;
    return resource;
  };

  const updateResource = async (
    applicationId: string,
    resourceId: string,
    resource: IResource,
    callback: (data: IResource) => void,
  ) => {
    delete (resource as any).code;
    return requestAsync(
      `/applications/${applicationId}/resources/${resourceId}`,
      'put',
      mapParentResourceId(resource),
      callback,
    );
  };

  const createResource = async (applicationId: string, resource: IResource, callback: (data: IResource) => void) => {
    return requestAsync(`/applications/${applicationId}/resources`, 'post', mapParentResourceId(resource), callback);
  };

  const removeResource = async (
    applicationId: string,
    resourceId: string,
    callback: (data: IResource) => void,
  ): Promise<any> => {
    return await requestAsync(`/applications/${applicationId}/resources/${resourceId}`, 'delete', {}, (data) =>
      callback(data),
    );
  };

  return {
    data,
    error,
    loading,
    loadingRecursive,
    getAll,
    getAllWithLimit,
    getCompleteData,
    getResource,
    updateResource,
    createResource,
    removeResource,
  };
};
