import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { useState, useEffect } from 'react';
import { RowSelectionType } from 'antd/es/table/interface';
import { useNavigate, useParams } from 'react-router-dom';
import usePermissions from 'hooks/usePermissions';

const { Column } = Table;

export default (props: {
  apps: any;
  applicationSelected: string;
  loading: boolean;
  onAppChange: (app: string) => void;
}) => {
  const { apps, applicationSelected, onAppChange, loading } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
  const { applicationId } = useParams();
  const { checkPermissions } = usePermissions();

  const navigate = useNavigate();

  const onSelectedRowKeysChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
    // Emit to parent
    const chosenApp = apps.filter((app: { label: string }) => {
      return app.label === selectedRowKeys[0];
    });
    if (typeof onAppChange === 'function' && chosenApp) onAppChange(chosenApp[0].code);
    if (chosenApp) localStorage.setItem('application', JSON.stringify(chosenApp[0]));
  };

  const goToPage = (label: any, link: string) => {
    onSelectedRowKeysChange([label]);
    navigate(link);
  };

  const editCta = (app: any) => {
    return (
      checkPermissions(applicationId, 'applications:update') && (
        <Button type='link' onClick={() => goToPage(app.label, `./../${app.code}/${app.id}/edit`)}>
          <EditOutlined style={{ fontSize: '18px' }} />
        </Button>
      )
    );
  };

  const renderActions = (app: any) =>
    editCta(app) ? (
      <Space size='middle'>
        <>{editCta(app)}</>
      </Space>
    ) : (
      '-'
    );

  const rowSelection = {
    type: 'radio' as RowSelectionType,
    columnWidth: '70px',
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  useEffect(() => {
    const appKey = apps?.filter((v: any) => v.value == applicationSelected);
    if (appKey?.length) {
      const targetApp = appKey[0];
      setSelectedRowKeys([targetApp?.label]);
      localStorage.setItem('application', JSON.stringify(appKey[0]));
    }
  }, [apps, applicationSelected]);

  return (
    <>
      <Table
        rowKey='name'
        rowSelection={rowSelection}
        dataSource={apps}
        pagination={false}
        loading={loading}
        style={{ overflowY: 'auto' }}
      >
        <Column
          title='Name'
          render={(app: any) => (
            <Button type='link' onClick={() => goToPage(app.label, `./../${app.code}/${app.id}`)}>
              {app.label}
            </Button>
          )}
        />
        <Column
          title='Compatibility Mode'
          key='compatibilitymode'
          align='center'
          width='200px'
          render={(app: any) =>
            app.oktaCompatibilityMode ? (
              <CheckOutlined style={{ fontSize: '18px', color: 'var(--color-primary-light)' }} />
            ) : null
          }
        />
        <Column
          title='Code'
          key='code'
          align='center'
          width='100px'
          render={(app: any) => <span className='uppercase'>{app.code}</span>}
        />
        <Column title='Actions' key='app-actions' align='center' width='100px' render={renderActions} />
      </Table>
    </>
  );
};
