import useAxios from './useAxios';
import { useState } from 'react';

export interface IRole {
  applicationCode: string;
  applicationId: string;
  applicationRoleCode: string;
  code: string;
  created: string;
  description: string;
  locked: boolean;
  id: string;
  name: string;
  updated: string;
}

export default (): {
  data: any;
  error: any;
  loading: boolean;
  loadingRecursive: boolean;
  getAll: (applicationId: string) => Promise<IRole[]>;
  getAllWithLimit: (applicationId: string, limit: number, offset?: number) => Promise<IRole[]>;
  getCompleteData: (applicationId: string) => Promise<any>;
  getRoles: (applicationId: string, roleId: string) => void;
  getRole: (applicationId: string, resourceId: string) => void;
  updateRole: (
    applicationId: string,
    resourceId: string,
    resource: IRole,
    callback: (data: IRole) => void,
  ) => Promise<any>;
  createRole: (applicationId: string, resource: IRole, callback: (data: IRole) => void) => Promise<any>;
  removeRole: (applicationId: string, resourceId: string, callback: (data: IRole) => void) => Promise<IRole>;
  searchRole: (applicationId: string, callback?: (data: any) => void) => Promise<any>;
} => {
  const { data, loading, error, request, requestAsync } = useAxios();

  const [loadingRecursive, setLoadingRecursive] = useState(false);

  const getAll = async (applicationId: string): Promise<any> => {
    return requestAsync(`/applications/${applicationId}/roles`).then((payload) => payload?.data?.items || []);
  };

  const getAllWithLimit = async (applicationId: string, limit: number, offset?: number): Promise<any> => {
    return requestAsync(`/applications/${applicationId}/roles?limit=${limit}&offset=${offset || 0}`).then(
      (payload) => payload?.data?.items || [],
    );
  };

  const getCompleteData = async (applicationId: string): Promise<any> => {
    let offset = 0;
    let total = 0;
    const items: any[] = [];
    setLoadingRecursive(true);

    const urlData = async () => {
      const url = `/applications/${applicationId}/roles?limit=${200}&offset=${offset}`;
      try {
        await requestAsync(url).then((payload) => {
          if (payload?.data?.items.length) {
            total = payload?.data?.total;
            items.push(...payload.data.items);
            if (total > items.length) {
              offset += 200;
              urlData();
            } else {
              setLoadingRecursive(false);
            }
          } else {
            setLoadingRecursive(false);
          }
        });
      } catch (e) {
        console.log('e=>', e);
        setLoadingRecursive(false);
      }
    };
    urlData();
  };

  const getRoles = (applicationId: string, roleId: string): void => {
    request(`/applications/${applicationId}/roles/${roleId}`);
  };

  const getRole = (applicationId: string, roleId: string): void => {
    request(`/applications/${applicationId}/roles/${roleId}`);
  };

  const updateRole = async (applicationId: string, roleId: string, role: IRole, callback: (data: IRole) => void) => {
    delete (role as any).code;
    return requestAsync(`/applications/${applicationId}/roles/${roleId}`, 'put', role, callback);
  };

  const createRole = async (applicationId: string, role: IRole, callback: (data: IRole) => void) => {
    return requestAsync(`/applications/${applicationId}/roles`, 'post', role, callback);
  };

  const removeRole = async (applicationId: string, roleId: string, callback: (data: IRole) => void): Promise<any> => {
    return requestAsync(`/applications/${applicationId}/roles/${roleId}`, 'delete', {}, (data) => callback(data));
  };

  const searchRole = async (applicationId: string, callback?: (data: IRole) => void): Promise<any> => {
    // This function will be changed with a new search endpoint.
    let offset = 0;
    let total = 0;
    const items: any[] = [];
    setLoadingRecursive(true);

    const urlData = async () => {
      const url = `/applications/${applicationId}/roles?limit=${200}&offset=${offset}`;
      try {
        await requestAsync(url, 'get', null, callback).then((payload) => {
          if (payload?.data?.items.length) {
            total = payload?.data?.total;
            items.push(...payload.data.items);
            if (total > items.length) {
              offset += 200;
              urlData();
            } else {
              setLoadingRecursive(false);
            }
          } else {
            setLoadingRecursive(false);
          }
        });
      } catch (e) {
        console.log('e=>', e);
        setLoadingRecursive(false);
      }
    };
    urlData();
  };

  return {
    data,
    error,
    loading,
    loadingRecursive,
    getAll,
    getAllWithLimit,
    getCompleteData,
    getRoles,
    getRole,
    updateRole,
    createRole,
    removeRole,
    searchRole,
  };
};
