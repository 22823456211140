import { usePermissionsStore } from 'store/permissionsStore';
import useAxios from './useAxios';

export default (): {
  data: any;
  error: any;
  loading: boolean;
  getPermissions: () => void;
  checkPermissions: any;
} => {
  const { data, error, request, loading: loading } = useAxios();
  const permissionsStored = usePermissionsStore();

  const getPermissions = () => {
    try {
      request(`/me/permissions`);
    } catch (e) {
      console.log('permission-error=>', e);
    }
  };

  const checkPermissions = (appName: string, permissionToCheck: string) => {
    let allPermissions: string[] = [];
    const permStored = permissionsStored?.permissions;

    const resourceAny = permStored?.filter((el) => el.resource.toString() === 'any');

    const tempResourcePermission = resourceAny?.length ? resourceAny[0].permissions : [];
    if (tempResourcePermission) allPermissions.push(...tempResourcePermission);

    const resourcePermission = permStored?.filter((el) => el.resource.toString() === appName)[0];
    if (resourcePermission?.permissions) allPermissions.push(...resourcePermission.permissions);

    allPermissions = [...new Set(allPermissions)];

    const logic = allPermissions?.some((el) => el.toString() === permissionToCheck);
    return logic;
  };

  return {
    data,
    error: error,
    loading,
    getPermissions,
    checkPermissions,
  };
};
