const baseURL = process.env.REACT_APP_AUTH_MANAGER_BASE_URL;
const importBaseUrl = process.env.REACT_APP_IMPORT_BASE_URL || '';
const clientId = process.env.REACT_APP_CLIENT_ID;
const issuer = process.env.REACT_APP_ISSUER;
const requiredAssignment = process.env.REACT_APP_AUTH_MANAGER_REQUIRED_ASSIGNMENT || 'amgr.superadmin';
const environment = process.env.NODE_ENV;

export default {
  okta: {
    clientId,
    issuer,
    pkce: true,
    scopes: ['openid', 'email', 'profile', 'offline_access', 'assignments:xp', 'assignments:amgr', 'assignments:mst'],
  },
  authManager: {
    baseURL,
    requiredAssignment,
    environment,
  },
  import: {
    baseURL: importBaseUrl,
  },
};
