import useResources, { IResource } from './useResources';
import useRoles, { IRole } from './useRoles';
import { useEffect, useState } from 'react';
import useAxios from './useAxios';

export default (): {
  error: any;
  loading: boolean;
  resources: IResource[];
  assignments: any;
  roles: IRole[];
  getAll: (applicationId: string) => Promise<any>;
  getAllApplicationAssignments: (applicationId: string) => Promise<any>;
  loadingResource: boolean;
  loadingRoles: boolean;
  loadingAssignments: boolean;
} => {
  const { data: payload, requestAsync, loading: loadingAssignments } = useAxios();
  const { getAll: getAllResources, error: errorResource, loading: loadingResource } = useResources();
  const { error: errorRoles, loading: loadingRoles, getAllWithLimit: getRoles } = useRoles();
  const [resources, setResources] = useState<IResource[]>([]);
  const [assignments, setAssignments] = useState<any[]>([]);
  const [roles, setRoles] = useState<IRole[]>([]);

  useEffect(() => {
    setAssignments(payload?.items || []);
  }, [payload]);

  const getAll = async (applicationCode: string): Promise<any> => {
    return Promise.allSettled([getAllResources(applicationCode), getRoles(applicationCode, 1000, 0)]).then((result) => {
      const [resourcesResult, rolesResult] = result;
      const resources = resourcesResult?.status === 'fulfilled' ? resourcesResult.value : [];
      const roles = rolesResult?.status === 'fulfilled' ? rolesResult.value : [];
      const roleMap = roles.reduce((acc, { id, code }: IRole) => acc.set(id, code) && acc, new Map());
      const assignments = [
        roles.map(({ code }: IRole) => `${applicationCode}.${code}`),
        ...resources.map(({ code, allowedRoleIds, parentResource }: IResource) =>
          (allowedRoleIds || [])
            .filter((id: string) => roleMap.has(id))
            .map(
              (id: string) =>
                `${applicationCode}.${
                  parentResource?.code ? `${parentResource.code}.${code}` : `${code}`
                }.${roleMap.get(id)}`,
            ),
        ),
      ].flat();

      setResources(resources);
      setRoles(roles);
      setAssignments(assignments);

      return assignments;
    });
  };

  const getAllApplicationAssignments = async (applicationId: string): Promise<void> => {
    return await requestAsync(`applications/${applicationId}/assignments`);
  };

  return {
    assignments,
    resources,
    roles,
    error: errorResource || errorRoles,
    loading: loadingResource || loadingRoles || loadingAssignments,
    getAll,
    getAllApplicationAssignments,
    loadingResource,
    loadingRoles,
    loadingAssignments,
  };
};
