import { Link, useLocation, useParams } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { RouteObject } from 'react-router/lib/router';
import routes from '../conf/routes';

const convertRoutesToBreadcrumbItems = (routes: RouteObject[], params: any) => {
  const items: Map<string, Record<string, string>> = new Map<string, Record<string, string>>();
  const { applicationId } = params;
  const replaceUrlParam = (path: string, params: any): string =>
    path
      .split('/')
      .map((param) => {
        if (!param.startsWith(':')) return param;
        if (params[param.slice(1)]) {
          return params[param.slice(1)];
        }
      })
      .join('/');

  const setItem = (
    route: { index?: boolean; breadcrumbName: string; path: string; children?: any },
    breadcrumbItem: Record<string, string> = {},
  ) => {
    const path = replaceUrlParam(route.path, params);
    const app = localStorage.getItem('application') || '{ label: "" }';
    breadcrumbItem[path] =
      path === `/${applicationId}` ? JSON.parse(app).label : route.breadcrumbName || path.split('/').reverse()[0];

    for (const child of route.children || []) {
      if (!child.index) {
        const newPath = child.path.length === 1 ? child.path : `${path === '/' ? '' : path}/${child.path}`;
        setItem({ ...child, path: newPath }, breadcrumbItem);
      }
    }
  };
  return routes.reduce((acc: any, route: any) => {
    acc[route.path] = route.breadcrumbName;
    setItem(route, acc);
    return acc;
  }, items);
};

export default () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const params = useParams();
  const breadcrumbNameMap = convertRoutesToBreadcrumbItems(routes, params);

  const extraBreadcrumbItems = pathSnippets.map((_: any, index, items) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        {index === items.length - 1 ? breadcrumbNameMap[url] : <Link to={url}>{breadcrumbNameMap[url]}</Link>}
      </Breadcrumb.Item>
    );
  });

  // Feel free to remove this ('isAuthManager &&') once each platform has its own layout
  const isAuthManager = JSON.parse(localStorage.getItem('application') || '{}').value;
  const breadcrumbItems = [
    isAuthManager && (
      <Breadcrumb.Item key='home'>
        <Link to='/'>Applications</Link>
      </Breadcrumb.Item>
    ),
  ].concat(extraBreadcrumbItems);

  return <Breadcrumb style={{ margin: '16px 0' }}>{breadcrumbItems}</Breadcrumb>;
};
