import { useEffect, useRef, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import conf from '../conf';
import { useOktaAuth } from '@okta/okta-react';

export default (
  url?: string,
  method?: string,
  payload?: any,
): {
  cancel: () => void;
  data: any;
  error: any;
  loading: boolean;
  request: (url: string, method?: string, payload?: any) => void;
  setData: (data: any) => void;
  setLoading: (status: boolean) => void;
  requestAsync: (
    url: string,
    method?: string,
    payload?: any,
    callback?: (data: any) => void,
    baseUrl?: string,
    headers?: Record<string, string>,
  ) => Promise<any>;
} => {
  const [data, setData] = useState(null);
  const [error, setError] = useState();

  // At this point no request has been made, loading should not default to true.
  const [loading, setLoading] = useState(true);
  const controllerRef = useRef(new AbortController());
  const { authState } = useOktaAuth();
  const [axiosRequest, setAxiosRequest] = useState({ url, method, payload });
  const headers = {
    Authorization: 'Bearer ' + authState?.accessToken?.accessToken,
    'Content-Type': 'application/json',
  };

  const cancel = () => {
    controllerRef.current.abort();
  };

  const request = (newUrl: string, newMethod?: string, newPayload?: any): void => {
    setAxiosRequest({
      url: newUrl,
      method: newMethod,
      payload: newPayload,
    });
  };

  // TODO: convert parameters to object
  const requestAsync = async (
    url: string,
    method = 'get',
    data?: unknown,
    callback?: (data: unknown) => void,
    baseUrl?: string,
    reqHeaders?: Record<string, string>,
  ) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      axios
        .request({
          data,
          signal: controllerRef.current.signal,
          baseURL: baseUrl ?? conf.authManager.baseURL,
          method,
          headers: reqHeaders || headers,
          url,
        })
        .then((response: AxiosResponse) => {
          // console.log(`requestAsync => [${axiosRequest.method || 'get'}] ${url || axiosRequest.url}`, response.data);
          setAxiosRequest({ url: undefined, method: undefined, payload: undefined });
          callback ? callback(response.data) : setData(response.data);
          setError(undefined);
          resolve(response);
        })
        .catch((error) => {
          setError(error?.code === 'ERR_NETWORK' ? error : (error.response as any));
          if (callback) callback(error);
          reject(error);
        })
        .finally(() => setLoading(false));
    });
  };

  useEffect(() => {
    axiosRequest.url && requestAsync(axiosRequest.url, axiosRequest.method, axiosRequest.payload);
  }, [axiosRequest.url, axiosRequest.method]);

  return { cancel, data, error, loading, request, requestAsync, setData, setLoading };
};
