import { Spin } from 'antd';

import useUsers, { IUser } from '../hooks/useUsers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import TagList from './TagList';

export default (props: { user: IUser }) => {
  const { applicationId = '' } = useParams();
  const { assignments: data, getAssignments, loadingAssignment } = useUsers();
  const [assignments, setAssignments] = useState<string[]>([]);

  useEffect(() => {
    if (props.user?.assignments) {
      setAssignments(props.user?.assignments);
    } else {
      getAssignments(applicationId, props.user.id);
    }
  }, [applicationId, props.user]);

  useEffect(() => {
    if (data?.length) {
      setAssignments(data.map(({ code = '' }) => code));
    }
  }, [data, loadingAssignment]);
  return (
    <>
      {loadingAssignment && !props.user?.assignments ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <TagList tags={assignments} />
      )}
    </>
  );
};
