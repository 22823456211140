import { Button, notification, Select, Space, Spin } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Page, RoleTable } from '../components';
import { useEffect, useState } from 'react';
import useRoles, { IRole } from '../hooks/useRoles';
import { ApartmentOutlined, SearchOutlined } from '@ant-design/icons';
import useLocalStorage from 'hooks/useLocalStorage';
import generalContent from '../conf/generalContent.json';
import useQuery from 'hooks/useQuery';
import usePermissions from 'hooks/usePermissions';
import Typography from 'antd/lib/typography';

const { Option, OptGroup } = Select;

const { Text, Paragraph } = Typography;

interface IOptionValue {
  key: string;
  label: string;
  value: string;
}

export default () => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [rolesAll, setRolesAll] = useState<IRole[]>([]);
  const [total, setTotal] = useState(0);
  const [userInput, setUserInput] = useState<string>('');
  const [options] = useState<IOptionValue[]>([]);
  const [searchInputValue, setSearchInputText] = useState<IOptionValue[]>([]);
  const { data, loading, getAllWithLimit, removeRole, error, searchRole } = useRoles();
  const { checkPermissions } = usePermissions();
  const [lsPageSize] = useLocalStorage<number>('am-page-size', 10);
  const query = useQuery();
  const [search] = [query.get('search')];

  const navigate = useNavigate();

  const onDelete = async (role: IRole) => {
    return await removeRole(applicationId, role?.id, (response: any) => {
      if (response?.name !== 'AxiosError') {
        notification.success({
          message: 'Success',
          description: `Resource ${role?.name} was successfully deleted`,
        });
        if (data) data.items = (data.items as IRole[]).filter((val) => val.id !== role?.id);
      }
    });
  };

  const onPageChange = (val: number, pageSize: number) => {
    getAllWithLimit(applicationId || '', pageSize, (val - 1) * pageSize);
  };

  const onSearch = (value: string) => {
    setSearchInputText([]);
    setUserInput(value || '');
  };

  const onChange = (value: IOptionValue[], options: IOptionValue | IOptionValue[]) => {
    setSearchInputText([]);
    setUserInput(value[0]?.value || '');

    let url = ``;
    if (options && Array.isArray(options) && options.length) {
      const option: IOptionValue = options.reduce((acc, opt) => {
        if (opt) acc = opt;
        return acc;
      });
      const [_, type] = option.key.split('-');
      if (type) {
        url = `?${type}=${encodeURIComponent(option.value)}`;
      }
    }
    setSearchInputText([...value]);
    navigate(url);

    if (!rolesAll.length && search) {
      searchRole(applicationId || '', (payload) => {
        if (payload?.items) setRolesAll(payload.items);
      });
    }
  };

  useEffect(() => {
    if (!rolesAll.length && search) {
      setSearchInputText([{ value: search || '', label: search || '', key: search || '' }]);

      searchRole(applicationId || '', (payload) => {
        if (payload?.items) setRolesAll(payload.items);
      });
    }

    if (search) {
      const filteredRoles = rolesAll.filter((role: IRole) => {
        const roleName = role.name.toLocaleLowerCase();
        const searchItem = search?.toLocaleLowerCase();
        return roleName.indexOf(searchItem || '') > -1;
      });

      setRoles(filteredRoles);
      setTotal(0);
    }
    if (!search && rolesAll.length) {
      getAllWithLimit(applicationId || '', lsPageSize);
    }
  }, [search, rolesAll]);

  useEffect(() => {
    if (error?.data) {
      const key = `error-notification`;
      const btn = error.data.assignments && (
        <Button danger onClick={() => notification.close(key)}>
          Close
        </Button>
      );

      notification.error({
        message: 'Attention',
        key,
        description: (
          <div>
            <Text>{`${error?.data?.error || 'Error occur during the operation'}`}</Text>
            <br />
            <br />
            {error?.data?.assignments && <Text strong>Assignments:</Text>}
            {error?.data?.assignments && (
              <Paragraph>
                <ul>
                  {((error?.data?.assignments as any) || []).map((assignment: string) => (
                    <li key={`li-item-${assignment}`}>
                      <Typography.Link onClick={() => navigate(`/${applicationId}/assignments/${assignment}/users`)}>
                        {assignment}
                      </Typography.Link>
                    </li>
                  ))}{' '}
                </ul>
              </Paragraph>
            )}
            <Space></Space>
          </div>
        ),
        btn,
        duration: !error.data.assignments ? null : 5,
      });
    }
  }, [error]);

  useEffect(() => {
    if (!search) {
      getAllWithLimit(applicationId || '', lsPageSize);
      setSearchInputText([]);
    }
    setRolesAll([]);
  }, [applicationId]);

  useEffect(() => {
    if (data?.items && data?.total) {
      setRoles(data.items || []);
      setTotal(data.total);
    }
  }, [data, data?.items]);

  const searchComponent = (
    <Space key='search-box'>
      <Select
        className='search-box_select'
        mode='multiple'
        key='search-values'
        placeholder='Search for a role'
        labelInValue
        value={searchInputValue}
        filterOption={false}
        onSearch={onSearch}
        notFoundContent={loading ? <Spin size='small' /> : null}
        onChange={onChange}
        style={{ minWidth: 200 }}
        suffixIcon={<SearchOutlined />}
        allowClear={true}
      >
        {userInput && (
          <OptGroup label={`Search:`}>
            <Option key={`opt-search-${userInput}`} value={userInput} label={userInput}>
              {userInput}
            </Option>
          </OptGroup>
        )}
        {options.length && (
          <OptGroup key={`opt-group-assignments`} label={`Assignments:`}>
            {(options || []).map((option: IOptionValue) => (
              <Option key={`opt-assignment-${option.value}`} value={option.value}>
                {option.label}
              </Option>
            ))}
          </OptGroup>
        )}
      </Select>
      <SearchOutlined style={{ color: '#a7a7a7' }} className='search-box_icon' />
    </Space>
  );

  const addRoleCta = checkPermissions(applicationId, 'roles:create') && (
    <Button key='add-role' type='primary'>
      <Link to={'create'}>Add Role</Link>
    </Button>
  );

  const extraElements = [searchComponent, addRoleCta];

  return (
    <Page
      title='Roles'
      extra={extraElements}
      titleIcon={<ApartmentOutlined />}
      description={generalContent.pages.roles.pageDescription}
    >
      <RoleTable roles={roles} loading={loading} onPageChange={onPageChange} onDelete={onDelete} total={total} />
    </Page>
  );
};
