import usePermissions from 'hooks/usePermissions';
import { useParams, Navigate } from 'react-router-dom';

export default (props: { permissions: string[]; children: JSX.Element }) => {
  const { applicationId } = useParams();
  const { checkPermissions } = usePermissions();
  const { permissions, children } = props;

  const allowNavigate = permissions.every((permission) => checkPermissions(applicationId, permission));

  if (!allowNavigate) {
    return <Navigate to='/' replace />;
  }

  return children;
};
