import useAxios from './useAxios';

export interface IInviteCodeItem {
  id?: string;
  appCode?: string;
  description: string;
  value: string;
  awardingAssignments: string[] | undefined;
  constraints?: {
    expiration?: string;
    usageLimit?: number;
    emailMatch?: string[];
  };
  active: boolean | string | undefined; // string as "active" or "inactive"
  deleted?: boolean;
  created?: string;
  updated?: string;
  numberOfRedemptions?: number;
  expired?: boolean;
  exhausted?: boolean;
}
export interface IInviteCodes {
  total: number;
  offset: number;
  items: IInviteCodeItem[];
}
export interface IInviteCodeEdit {
  description: string;
  active: boolean | undefined;
}

export default (): {
  data: any;
  error: any;
  loading: boolean;
  getAllWithLimit: (applicationId: string, limit: number, offset?: number, sort?: string) => Promise<IInviteCodes[]>;
  getInviteCode: (applicationId: string, inviteCode: string) => void;
  createInvite: (
    applicationId: string,
    invite: IInviteCodeItem,
    callback: (data: IInviteCodes) => void,
  ) => Promise<any>;
  updateInvite: (
    applicationId: string,
    inviteCode: string,
    invite: IInviteCodeEdit,
    callback: (data: any) => void,
  ) => Promise<any>;
} => {
  const { data, loading, error, requestAsync } = useAxios();

  const getAllWithLimit = async (
    applicationId: string,
    limit: number,
    offset?: number,
    sort?: string,
  ): Promise<any> => {
    return requestAsync(`/invite-codes/${applicationId}/?sort=${sort}&limit=${limit}&offset=${offset || 0}`).then(
      (payload) => payload?.data?.items || [],
    );
  };

  const getInviteCode = (applicationId: string, inviteCode: string) => {
    return requestAsync(`/invite-codes/${applicationId}/${inviteCode}`);
  };

  const createInvite = async (applicationId: string, invite: IInviteCodeItem, callback: (data: any) => void) => {
    return requestAsync(`/invite-codes/${applicationId}`, 'post', invite, callback);
  };

  const updateInvite = async (
    applicationId: string,
    inviteCode: string,
    invite: IInviteCodeEdit,
    callback: (data: any) => void,
  ) => {
    return requestAsync(`/invite-codes/${applicationId}/${inviteCode}`, 'put', invite, callback);
  };

  return {
    data,
    error,
    loading,
    getAllWithLimit,
    getInviteCode,
    createInvite,
    updateInvite,
  };
};
