import { EditOutlined } from '@ant-design/icons';
import { Space, Table, Tag } from 'antd';
import { IInviteCodeItem } from 'hooks/useInviteCodes';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PaginationGeneric from './PaginationGeneric';
import TagList from './TagList';
import usePermissions from 'hooks/usePermissions';

const { Column } = Table;

export default (props: {
  data: IInviteCodeItem[];
  total: number;
  loading: boolean;
  onPageChange?: (val: number, pageSize: number, sort: string) => void;
  onSortChange?: (sort: string) => void;
}) => {
  const { onPageChange, onSortChange, data, loading, total } = props;
  const [sort, setSort] = useState('value asc');
  const [resetPagination, setResetPagination] = useState(false);
  const { applicationId } = useParams();
  const { checkPermissions } = usePermissions();

  const editCta = (obj: any) => {
    return (
      checkPermissions(applicationId, 'inviteCode:update') && (
        <Space size='middle'>
          <Link to={`${obj}/edit`}>
            <span className='uppercase'>
              <EditOutlined style={{ fontSize: '18px' }} />
            </span>
          </Link>
        </Space>
      )
    );
  };

  const renderActions = (obj: any) =>
    editCta(obj) ? (
      <Space size='middle'>
        <>{editCta(obj)}</>
      </Space>
    ) : (
      '-'
    );

  const pageChange = (val: number, pageSize: number, sort: string) => {
    if (typeof onPageChange === 'function') onPageChange(val, pageSize, sort);
  };

  const handleChange = (_val: any, sorter: any) => {
    let sortVal = sorter?.order === 'descend' ? 'desc' : 'asc';
    const field = sorter.field; // value, status
    // Inverting logic for Status (true/false)
    if (field === 'active') sortVal = sortVal === 'desc' ? 'asc' : 'desc';

    setSort(`${field} ${sortVal}`);
    setResetPagination(true);
    setTimeout(() => setResetPagination(false), 500);
  };

  const handleExpiredExhaustedTags = (obj: boolean | undefined, columnName: string) => {
    // 'true' means the invite has expired or exhausted
    const trueTitle = columnName === 'expiredCol' ? 'expired' : 'exhausted';
    const falseTitle = columnName === 'expiredCol' ? 'current' : 'redemptions available';
    return (
      <span>
        {obj ? (
          <span title={trueTitle}>&#x274C;</span>
        ) : obj !== undefined ? (
          <span title={falseTitle}>&#x1F7E2;</span>
        ) : (
          '-'
        )}
      </span>
    );
  };

  useEffect(() => {
    if (typeof onSortChange === 'function' && sort) onSortChange(sort);
  }, [sort]);

  return (
    <>
      <Table
        rowKey='value'
        dataSource={data}
        pagination={false}
        loading={loading}
        style={{ overflowY: 'auto' }}
        sortDirections={['ascend', 'descend', 'ascend']}
        onChange={(val: any, _filter: any, sorter: any, _extra: any) => {
          handleChange(val, sorter);
        }}
      >
        <Column
          title='Invite Code'
          key='inviteCode'
          dataIndex='value'
          defaultSortOrder='ascend'
          sorter={true}
          showSorterTooltip={false}
          width='400px'
          render={(obj) => (
            <Link to={`${obj}`}>
              <span className='uppercase'>{obj}</span>
            </Link>
          )}
        />
        <Column
          title='Description'
          key='description'
          dataIndex='description'
          width='400px'
          render={(obj) => <span>{obj?.length ? obj : '-'}</span>}
        />
        <Column
          title='Status'
          key='status'
          dataIndex='active'
          sorter={true}
          showSorterTooltip={false}
          width='100px'
          render={(obj) => <span>{obj ? <Tag color='green'>active</Tag> : <Tag color='red'>inactive</Tag>}</span>}
        />
        <Column
          title='Assignments'
          key='awardingAssignments'
          dataIndex='awardingAssignments'
          render={(obj) => <TagList tags={obj} />}
        />
        <Column
          title='Expired'
          key='expired'
          dataIndex='expired'
          width='100px'
          align='center'
          render={(obj) => handleExpiredExhaustedTags(obj, 'expiredCol')}
        />
        <Column
          title='Redemptions'
          key='numberOfRedemptions'
          dataIndex='numberOfRedemptions'
          width='150px'
          align='center'
          render={(obj) => <span>{obj}</span>}
        />
        <Column
          title='Exhausted'
          key='exhausted'
          dataIndex='exhausted'
          width='100px'
          align='center'
          render={(obj) => handleExpiredExhaustedTags(obj, 'exhaustedCol')}
        />
        <Column
          title='Actions'
          key='app-actions'
          align='center'
          width='100px'
          render={(obj) => renderActions(obj?.value)}
        />
      </Table>

      <PaginationGeneric
        total={total}
        hideOnSinglePage={true}
        showSizeChanger={true}
        resetPagination={resetPagination}
        onPageChange={(page, pageSize) => {
          pageChange(page, pageSize, sort);
        }}
      ></PaginationGeneric>
    </>
  );
};
