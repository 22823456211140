import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PaginationGeneric from './PaginationGeneric';
import useLocalStorage from 'hooks/useLocalStorage';

const { Column } = Table;

export default (props: {
  assignments: any;
  total: number;
  loading: boolean;
  onPageChange?: (val: number, pageSize: number) => void;
}) => {
  const { assignments, total, loading } = props;
  const [lsPageSize] = useLocalStorage<number>('am-page-size', 10);
  const [assignmentsArr, setAssignmentsArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeInner, setPageSizeInner] = useState(lsPageSize);

  useEffect(() => {
    setAssignmentsArr(assignments);
  }, [assignments]);

  const pageChange = (currentPage: number, pageSize: number) => {
    setCurrentPage(currentPage);
    setPageSizeInner(pageSize);
  };

  // Amount of data to pass to Table
  const calPage = (currentPage - 1) * pageSizeInner;
  let assignmentsToShow = assignmentsArr?.slice(calPage, calPage + pageSizeInner);
  useEffect(() => {
    if (assignmentsArr && assignmentsArr.length > pageSizeInner) {
      assignmentsToShow = assignmentsArr?.slice(calPage, calPage + pageSizeInner);
      if (!assignmentsToShow?.length) {
        pageChange(1, pageSizeInner);
      }
    } else {
      assignmentsToShow = assignmentsArr;
      if (assignmentsToShow?.length) setCurrentPage(1);
    }
  }, [assignmentsToShow, total]);

  return (
    <>
      <Table rowKey='code' dataSource={assignmentsToShow} pagination={false} loading={loading}>
        <Column
          title='Name'
          key='name'
          render={(assignment: any) => <Link to={assignment.code}>{assignment.code}</Link>}
        />
        <Column
          title='Users'
          key='users'
          align='center'
          render={(assignment: any) => <Link to={`${assignment.code}/users`}>{assignment.users}</Link>}
        />
        <Column
          title='Clients'
          key='clients'
          align='center'
          render={(assignment: any) => <Link to={`${assignment.code}/clients`}>{assignment.clients}</Link>}
        />
      </Table>

      <PaginationGeneric
        loading={loading}
        total={total}
        hideOnSinglePage={true}
        showSizeChanger={true}
        onPageChange={(page, pageSize) => {
          pageChange(page, pageSize);
        }}
      ></PaginationGeneric>
    </>
  );
};
