import { Result } from 'antd';
import { useParams } from 'react-router-dom';
import { AssignmentsTable, Page } from '../components';
import { useEffect, useState } from 'react';
import useAssignments from '../hooks/useAssignments';
import { ApiOutlined } from '@ant-design/icons';
import useApplications from '../hooks/useApplications';
import generalContent from '../conf/generalContent.json';

export default () => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const { error, getAllApplicationAssignments, getAll } = useAssignments();
  const [items, setItems] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const { isItOktaAssignmentMapped } = useApplications();
  const counting = new Map();

  const setCounting = (code: string, users = 0, clients = 0) => {
    if (!items.includes(code)) {
      items.push({ code, users, clients });
    }
    counting.set(code, { code, users, clients });
    return { code, users, clients };
  };

  useEffect(() => {
    if (applicationId) {
      setLoading(true);
      setItems([]);
      getAll(applicationId || '').then(async (assignments: string[]) => {
        if (assignments.length > 0) {
          setItems(
            (assignments || [])
              .filter((assignment: string) => !isItOktaAssignmentMapped(assignment))
              .map((code: string) => setCounting(code)),
          );
          setTotal(assignments.length);
        }
      });
    }
  }, [applicationId]);

  useEffect(() => {
    if (items.length > 0 && loading) {
      getAllApplicationAssignments(applicationId).then((appAssignments: any) => {
        let filteredItems: any = [];
        (appAssignments.data.items || []).map((assignment: any) =>
          setCounting(assignment.code, assignment.users, assignment.clients),
        );

        // Remove duplications
        filteredItems = items
          .map((item: any) => counting.get(item.code) || item)
          .filter(
            (value: any, index, self) => index === self.findIndex((indexValue) => indexValue.code === value.code),
          );
        setItems(filteredItems);
        setTotal(filteredItems.length);
        setLoading(false);
      });
    }
  }, [items]);

  return (
    <Page
      title={'Assignments'}
      titleIcon={<ApiOutlined title={'Assignments'} />}
      description={generalContent.pages.assignments.pageDescription}
    >
      {error ? (
        <Result title={error?.data?.error || ''} />
      ) : (
        <AssignmentsTable assignments={items} loading={loading} total={total} />
      )}
    </Page>
  );
};
