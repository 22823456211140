import { useEffect, useRef } from 'react';

/**
 * usePrevious
 * This function basically create an object reference and
 * it useful to get the previous value inside a useEffect function.
 *
 * @param value any
 * @returns any
 */
export default (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
