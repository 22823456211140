import './index.scss';
import { OktaSecurity } from './components';
import { useRoutes } from 'react-router-dom';
import routes from './conf/routes';

function App() {
  return <OktaSecurity>{useRoutes(routes)}</OktaSecurity>;
}

export default App;
