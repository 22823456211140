import { UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';

export default () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfo = await oktaAuth.getUser();
        setUserInfo(userInfo);
      } catch (e) {
        setUserInfo(undefined);
      }
    };
    fetchData();
  }, [authState, oktaAuth]);

  return userInfo;
};
