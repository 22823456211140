import conf from '../conf';
import { FileAddOutlined } from '@ant-design/icons';
import generalContent from '../conf/generalContent.json';

import { Page } from '../components';
import { useEffect } from 'react';
import { Col, Descriptions, Progress, Result, Row, Spin, Table, Tooltip, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import useAxios from 'hooks/useAxios';

const { Paragraph, Title } = Typography;
const { Item } = Descriptions;

type SyncStatus = {
  created: string;
  elapsedTime: number | null;
  fileName: string;
  isCancelled: boolean;
  messages: { timestamp: string; message: string }[];
  output?: { data: { rowNumber: number }; outcome: ({ status: number } & Record<string, unknown>)[] }[];
  pending?: number;
  processed?: number;
  syncId: string;
  total: number;
};

type FailedRecord = { key: number; rowNumber: number; status: number; details: string };

export default () => {
  const screens = useBreakpoint();
  const {
    data: status,
    error,
    loading,
    requestAsync,
  } = useAxios() as Record<string, any> & { data: SyncStatus | null };
  const { syncId } = useParams();
  const errorMessage = typeof error?.data?.message === 'string' ? error.data.message : 'Unknown error';
  const resource = 'users'; // The resource should eventually come from url params to be dynamic

  const totalPercentage = status ? Math.floor(((status.processed ?? 0) / status.total) * 100) : 0;
  // A record is considered successful when its status is less than 400. The API can typically respond 201, 400 or 500
  const successfulRecords = status?.output
    ? status.output.reduce((acc, curr) => {
        return acc + (curr?.outcome?.[0]?.status < 400 ? 1 : 0);
      }, 0)
    : 0;
  const successPercentage = status && successfulRecords ? Math.floor((successfulRecords / status.total) * 100) : 0;
  const failedRecords = (status?.output?.filter((record) => record.outcome?.[0]?.status >= 400) || [])
    .map((record, index) => {
      const { status, ...rest } = record.outcome?.[0] || {};
      return {
        key: record.data?.rowNumber ?? index,
        rowNumber: record.data?.rowNumber,
        status,
        details: JSON.stringify(rest),
      };
    })
    .sort((a, b) => a.rowNumber - b.rowNumber);

  /*
   * The sync status is fetched every n milliseconds to get fresh data
   * The interval is removed when there are no pending records, on error and on unmount
   * This effects runs only once
   * Note: In some cases the API response is not consistent when it's called immediately after creating the sync, so rather wait a bit
   */
  useEffect(() => {
    const ms = 30000;
    async function fetchData(intervalId: NodeJS.Timeout) {
      try {
        const { data: syncStatus } = (await requestAsync(`${conf.import.baseURL}/${resource}/sync/${syncId}`)) || {};

        // When there are error messages or there are no pending records, the interval is removed
        if (!syncStatus || syncStatus?.messages.length || syncStatus.pending <= 0) {
          clearInterval(intervalId);
        }
      } catch (e) {
        clearInterval(intervalId);
      }
    }
    const intervalId: NodeJS.Timeout = setInterval(() => fetchData(intervalId), ms);
    return () => clearInterval(intervalId);
  }, [conf.import.baseURL, resource, syncId]);

  const Loading = (
    <Row gutter={[24, 0]} style={{ height: '50vh', position: 'relative' }}>
      <Col style={{ width: '100%' }}>
        <Spin style={{ position: 'absolute', left: '50%', top: '40%' }} />{' '}
      </Col>
    </Row>
  );

  const ProgressBar = () => (
    <Tooltip title={`${status?.processed ?? 0} processed / ${successfulRecords} OK / ${status?.pending ?? 0} pending`}>
      <Progress
        percent={totalPercentage}
        showInfo
        success={{ percent: successPercentage }}
        strokeColor='red'
        trailColor='gray'
        type='circle'
      />
    </Tooltip>
  );

  const columns = [
    { title: 'Row', dataIndex: 'rowNumber', key: 'rowNumber' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Details', dataIndex: 'details', key: 'details' },
  ];

  const FailedRecords = ({ failedRecords = [] }: { failedRecords: FailedRecord[] }) => {
    return (
      <>
        <Table
          bordered
          columns={columns}
          dataSource={failedRecords}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '50', '100'] }}
          style={{ marginTop: 32 }}
          title={() => (
            <Title level={5} type='secondary'>
              Failed Records Details
            </Title>
          )}
        />
      </>
    );
  };
  const Details = ({ failedRecords = [] }: { failedRecords: FailedRecord[] }) => {
    const failedRows = failedRecords.map((record) => record.rowNumber);
    return (
      <Descriptions
        key='descApp'
        column={1}
        contentStyle={{}}
        layout={screens.md ? 'horizontal' : 'vertical'}
        size={'middle'}
        bordered
      >
        <Item key='Total' label='Total' labelStyle={{ width: '20%' }} contentStyle={{ width: '80%' }}>
          {status?.total}
        </Item>
        <Item key='Processed' label='Processed'>
          {status?.processed ?? 0}
        </Item>
        <Item key='Created' label='Created'>
          {successfulRecords}
        </Item>
        <Item key='Failed' label='Failed'>
          {failedRows.length && (
            <Paragraph copyable={{ text: failedRows.join(',') }} style={{ marginBottom: 0 }}>
              {failedRows.length}
              <span style={{ fontWeight: 'bold', marginLeft: '30px' }}>Copy failed row numbers</span>
            </Paragraph>
          )}
        </Item>
        {!!status?.messages.length && (
          <Item key={'messages'} label={'Messages'}>
            {status.messages.map(({ message }, index) => (
              <p style={{ marginBottom: 0 }} key={index}>
                {message}
              </p>
            ))}
          </Item>
        )}
      </Descriptions>
    );
  };

  return (
    <Page
      title={generalContent.pages.importStatus.title}
      titleIcon={<FileAddOutlined />}
      description={generalContent.pages.importStatus.pageDescription}
    >
      {loading && Loading}

      {error && <Result title={errorMessage} />}

      {status && (
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col key='progress' span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ProgressBar />
          </Col>
          <Col key='appDetail' className='applicationsPage-details'>
            <Details failedRecords={failedRecords} />
          </Col>
        </Row>
      )}

      {!!failedRecords.length && <FailedRecords failedRecords={failedRecords} />}
    </Page>
  );
};
