import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApplications from '../hooks/useApplications';

export default (props: {
  onChange: (value: { label: string; value: string }) => void;
  onError?: (error: any) => void;
  value: { label: string; value: string };
}) => {
  const [applications, setApplications] = useState([]);
  const { data, loading, getAll, error } = useApplications();
  const { applicationId } = useParams();
  const [selected, setSelected] = useState(props.value);

  useEffect(() => {
    error && props?.onError && props.onError(error);
  }, [error]);

  useEffect(() => {
    !data && getAll();
  }, [data]);

  useEffect(() => {
    const values = data?.items.map((application: { name: string; code: string }) => ({
      label: application.name,
      value: application.code,
    }));
    if (!props.value.value && Array.isArray(values) && values.length) {
      props.onChange(values[0]);
    }
    setApplications(values);
  }, [data, props.value.value]);

  const setOption = (value: any) => {
    const application = (data?.items || []).filter((item: any) => item.code === value).shift();
    props.onChange({ ...application, label: application.name, value: application.code });
  };

  useEffect(() => {
    if (applications) {
      setSelected(applications.filter(({ value }) => value === applicationId)[0]);
    }
  }, [applicationId, applications]);

  return (
    <Select
      className='application-select'
      defaultValue={selected}
      value={selected}
      bordered={false}
      allowClear={false}
      onChange={setOption}
      options={applications}
      showArrow={true}
      loading={loading}
    />
  );
};
