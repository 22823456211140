import { Result } from 'antd';
import { useParams } from 'react-router-dom';
import { Page, ClientTable } from '../components';
import { useEffect, useState } from 'react';
import useClients from '../hooks/useClients';
import { DesktopOutlined } from '@ant-design/icons';
import generalContent from '../conf/generalContent.json';

export default () => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const [clients, setClients] = useState([]);
  const { data, loading, getAll, error } = useClients();
  const { oktaCompatibilityMode = false } = JSON.parse(
    localStorage.getItem('application') || '{ oktaCompatibilityMode: false }',
  );

  useEffect(() => {
    if (!oktaCompatibilityMode) {
      getAll(applicationId || '');
    }
  }, [applicationId]);

  useEffect(() => {
    setClients(data?.items || []);
  }, [data]);

  return (
    <Page
      title={'Okta Clients'}
      titleIcon={<DesktopOutlined />}
      description={generalContent.pages.oktaClients.pageDescription}
    >
      {error || oktaCompatibilityMode ? (
        <Result
          title={
            error?.data?.error ||
            (oktaCompatibilityMode && 'Clients are not enabled for applications with Okta compatibility mode.')
          }
        />
      ) : (
        <ClientTable clients={clients} loading={loading} total={clients.length || 0} />
      )}
    </Page>
  );
};
