import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface Permissions {
  permissions: TPermissions[];
  setPermission: (val: TPermissions[]) => void;
  resetPermission: () => void;
}

type TPermissions = {
  permissions: string[];
  resource: string;
};

export const usePermissionsStore = create<Permissions>()(
  devtools((set) => ({
    permissions: [],
    setPermission: (val) =>
      set(() => ({
        permissions: val,
      })),
    resetPermission: () =>
      set(() => ({
        permissions: [],
      })),
  })),
);
