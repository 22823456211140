import conf from '../conf';
import { FileAddOutlined } from '@ant-design/icons';
import generalContent from '../conf/generalContent.json';
import ImportForm from 'components/importForm';
import { Page } from '../components';
import useAxios from 'hooks/useAxios';
import { UserClaims } from '@okta/okta-auth-js';
import { useEffect } from 'react';
import useUserInfo from 'hooks/useUserInfo';
import { Col, Result, Row, Spin } from 'antd';

type UploadInfo = {
  accessKeyId: string;
  base64Policy: string;
  date: Date;
  region: string;
  sessionToken: string;
  signature: string;
  userInfo: UserClaims;
  url: string;
};

export default ({ resource }: { resource: string }) => {
  const userInfo = useUserInfo();
  const { data, error, loading, request } = useAxios();

  const uploadInfo = data?.output as UploadInfo;
  const errorMessage =
    error &&
    ((typeof error.data?.message === 'string' && error.data.message) ||
      (typeof error.data === 'string' && error.data) ||
      'Unknown error');

  useEffect(() => {
    /**
     * Gets the data required to upload a file to the cloudProvider
     *  The server response should include the following:
     * `{
     *    accessKeyId: 'access key id',
     *    base64Policy: 'policy',
     *    date: epoch timestamp,
     *    region: 'us-west-2',
     *    sessionToken: 'token',
     *    signature: 'signature',
     *    url: 'cloud provider url to upload the file',
     *  }`
     */
    request(`${conf.import.baseURL}/permissions/${resource}`);
  }, [conf.import.baseURL, resource]);

  // TODO: extract to a functional component
  const Loading = (
    <Row gutter={[24, 0]} style={{ height: '50vh', position: 'relative' }}>
      <Col style={{ width: '100%' }}>
        <Spin style={{ position: 'absolute', left: '50%', top: '40%' }} />{' '}
      </Col>
    </Row>
  );

  return (
    <Page
      description={generalContent.pages.bulkImport.pageDescription}
      title={generalContent.pages.bulkImport.usersTitle}
      titleIcon={<FileAddOutlined />}
    >
      {error && <Result title={errorMessage} />}

      {loading && Loading}

      {uploadInfo && !error && <ImportForm {...{ ...uploadInfo, resource, userInfo, timestamp: Date.now() }} />}
    </Page>
  );
};
