import axios from 'axios';
import useAxios from 'hooks/useAxios';
import conf from '../conf';
import generalContent from '../conf/generalContent.json';
import DocsTooltip from './DocsTooltip';
import { UserClaims } from '@okta/okta-auth-js';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Result, Row, Spin, Typography, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import { xmlParser } from 'utils/utils.js';
import { UploadOutlined } from '@ant-design/icons';

const { Text } = Typography;
type UserFormData = { files: { name: string; originFileObj: File }[]; tag: string };

const {
  pages: {
    bulkImport: { file: fileLabel, tag: tagLabel },
  },
} = generalContent;

/*
 * Two different endpoints are called:
 *  the first one uploads the file to the cloud provider. This goes directly to the cloud provider.
 *  In case of error, the response may contain an xml that needs to be parsed to get the error message.
 *  In case of success, the response is ignored and a call to the server is made to start a synchronization.
 *  The response contains the syncId that is passed to the importStatusPage.
 */
const ImportForm = ({
  accessKeyId,
  base64Policy,
  date,
  region,
  resource,
  sessionToken,
  signature,
  timestamp,
  url,
  userInfo,
}: {
  accessKeyId: string;
  base64Policy: string;
  date: Date;
  region: string;
  resource: string;
  sessionToken: string;
  signature: string;
  timestamp: number;
  url: string;
  userInfo?: UserClaims;
}) => {
  const navigate = useNavigate();
  const [uploadedFilepath, setUploadedFilepath] = useState<string>('');
  const [isSubmitDisabled, disableSubmit] = useState<boolean>(false);
  const [uploadErrorMessage, setUploadError] = useState<string>('');
  const [syncErrorMessage, setSyncError] = useState<string>('');
  const { data, loading, requestAsync, setLoading } = useAxios();
  const { syncId } = (data as { syncId: string } | null) ?? {};

  /*
   * This effect triggers once the file upload is complete
   * It sends a request to the server to start a synchronization process
   * As a result, data should be populated with the syncId
   */
  useEffect(() => {
    if (uploadedFilepath) {
      const triggerSync = async () => {
        try {
          const payload = {
            bucket: (url.match(/\/\/([^/.]+)/) ?? [])[1], // Extracts 'bucket-name' from https://bucket-name.s3.amazonaws.com",
            key: uploadedFilepath,
          };

          await requestAsync(`${conf.import.baseURL}/${resource}/sync`, 'POST', payload);
        } catch (error) {
          if (axios.isAxiosError(error) && typeof error.response?.data === 'string') {
            setSyncError(error.response.data);
          } else {
            setSyncError(error instanceof Error ? error.message : 'Synchronization failed');
          }
        }
      };

      triggerSync();
    }
  }, [conf.import.baseURL, uploadedFilepath, resource]);

  /*
   * This effect triggers once the server responded with a syncId
   * It navigates to the sync status page
   */
  useEffect(() => {
    if (syncId) setTimeout(() => navigate(`/import/${syncId}`), 3000);
  }, [syncId]);

  /**
   * This effect is a workaround to set loading to false
   * since useAxios sets loading to true by default
   */
  useEffect(() => {
    setLoading(false);
  }, []);

  /**
   * This effect resets the error messages when the user clicks on the menu entry that renders the importPage component after an error occurred.
   * The importPage will re-render the importForm component, allowing it to display the form again.
   */
  useEffect(() => {
    disableSubmit(false);
    setUploadError('');
    setSyncError('');
  }, [timestamp]);

  // Given the form data, returns the filepath
  const buildFilePath = (data: UserFormData) => {
    return 'users/input/' + data.files[0].name;
  };

  // Returns the appropriate attributes that are required by S3
  // Follow the link for more details about it: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html
  const buildFormAttributes = (data: UserFormData) => {
    const extendedISODate = new Date(date).toISOString();
    const basicISODate = extendedISODate.replace(/[:-]|(\.\d{3})|/g, '');
    const yyyymmdd = basicISODate.split('T')[0];
    const credential = `${accessKeyId}/${yyyymmdd}/${region}/s3/aws4_request`;
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    return {
      key: buildFilePath(data),
      'X-Amz-Meta-Tag': data.tag ?? '',
      success_action_redirect: '',
      'Content-Type': contentType,
      'x-Amz-Server-Side-Encryption': 'AES256',
      'X-Amz-Credential': credential,
      'X-Amz-Algorithm': 'AWS4-HMAC-SHA256',
      'X-Amz-Date': basicISODate,
      'x-Amz-Security-Token': sessionToken,
      Policy: base64Policy,
      'X-Amz-Signature': signature,
      'X-Amz-Meta-User-Email': userInfo?.email || '',
      file: data.files[0].originFileObj,
    };
  };

  const handleSubmit = async (data: UserFormData) => {
    try {
      // The button to submit the form is disabled after clicking it to avoid multiple clicks
      disableSubmit(true);
      const attributes = buildFormAttributes(data);
      const formData = new FormData();
      for (const prop in attributes) {
        formData.append(prop, attributes[prop as keyof typeof attributes]);
      }

      await requestAsync(url, 'POST', formData, undefined, undefined, { 'Content-Type': 'multipart/form-data' });
      setUploadedFilepath(buildFilePath(data));
    } catch (error) {
      let parsedMessage = '';
      if (axios.isAxiosError(error) && error.response?.data) {
        // The error response from aws s3 can be an xml, so we try to parse it. Then we just add it to the error message.
        // For example: <?xml version="1.0"encoding="UTF-8"?><Error><Code>ExpiredToken</Code><Message>The provided token has expired.</Message></Error>
        const parsedXml = xmlParser(error.response.data, 'Message');
        parsedMessage = parsedXml ? `: ${parsedXml}` : '';
      }
      setUploadError(error instanceof Error ? error.message + parsedMessage : 'Unknown error');
    }
  };

  // TODO Extract to a functional component
  const Loader = (message?: string) => (
    <Row gutter={[24, 0]} style={{ height: '50vh', position: 'relative' }}>
      <Col style={{ width: '100%' }}>
        <Spin style={{ position: 'absolute', left: '50%', top: '40%' }} />{' '}
      </Col>
      {message && (
        <Col style={{ textAlign: 'center', width: '100%' }}>
          <Text>{message}</Text>
        </Col>
      )}
    </Row>
  );

  const UploadForm = () => (
    <Row gutter={[24, 0]}>
      <Col>
        <Form
          name='fileInputForm'
          method='post'
          encType='multipart/form-data'
          onFinish={handleSubmit}
          className='ui form'
        >
          <Form.Item
            htmlFor='tag'
            label='File Tag'
            name='tag'
            tooltip={DocsTooltip({ title: tagLabel })}
            style={{ maxWidth: '260.32px' }}
          >
            <Input id='tag' name='tag' placeholder='File Tag' />
          </Form.Item>
          <Form.Item
            getValueFromEvent={({ fileList }) => fileList}
            valuePropName='fileList'
            htmlFor='fileInput'
            label='Upload File'
            name='files'
            rules={[{ required: true, message: 'Please select a file' }]}
            tooltip={DocsTooltip({ title: fileLabel })}
          >
            <Upload id='fileInput' accept='.xlsx' beforeUpload={() => false} maxCount={1} showUploadList={true}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' disabled={!!isSubmitDisabled}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );

  return (
    <>
      {/* The error may occur when uploading the file, or when creating the sync */}
      {(uploadErrorMessage || syncErrorMessage) && <Result title={uploadErrorMessage || syncErrorMessage} />}

      {/* This message is displayed while waiting for the storage provider to upload the file */}
      {loading && !uploadedFilepath && Loader('Uploading a file might take some time depending on the size')}

      {/* This message is displayed after a successful file upload while waiting for a response from the create sync endpoint */}
      {uploadedFilepath &&
        !syncErrorMessage &&
        Loader(
          'File successfully uploaded. Synchronization has just started.You will be redirected to the sync status page',
        )}

      {!(uploadErrorMessage || syncErrorMessage || uploadedFilepath || loading) && <UploadForm />}
    </>
  );
};

export default ImportForm;
