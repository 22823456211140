export function setExpiredDate(targetDate) {
  const dateNow = new Date(new Date().toISOString());
  const newDateFromTarget = new Date(targetDate);
  return dateNow > newDateFromTarget;
}

/**
 * Parses the given XML to extract the content of the specified tag.
 * It does not throw on error and return input instead
 */
export function xmlParser(xml, tag) {
  try {
    const xmlDoc = new DOMParser().parseFromString(xml, 'text/xml');
    const node = xmlDoc.querySelector(tag);
    return node?.textContent || '';
  } catch (e) {
    return xml;
  }
}
