import { Select } from 'antd';
import { useEffect, useState } from 'react';
import useRoles, { IRole } from '../hooks/useRoles';

const { Option } = Select;

export default (props: {
  mode?: 'multiple' | 'tags';
  value?: any;
  allowedRoleIds?: string[];
  applicationId: string;
  readOnly?: boolean;
  className?: string;
  loading?: boolean;
  valueIndex?: keyof Omit<IRole, 'locked'>;
  onChange?: (value: string, id: string) => void;
  renderOptions?: (options: any[]) => { label: string; value: string; disabled?: boolean }[];
  showLockedRoles?: boolean;
}) => {
  const {
    mode,
    readOnly = false,
    className = '',
    allowedRoleIds,
    applicationId,
    value,
    valueIndex = 'id',
    onChange = () => undefined,
    renderOptions,
    showLockedRoles = false,
  } = props;
  const [roles, setRoles] = useState<{ label: string; value: string; id: string }[]>([]);
  const { data, loadingRecursive, getCompleteData } = useRoles();

  useEffect(() => {
    applicationId && getCompleteData(applicationId);
  }, [applicationId]);

  /**
   * When allowedRoleIds[] is empty, all the roles are displayed (except locked roles).
   * Otherwise, only roles that are in the allowedRoleIds[] will be displayed (except locked roles).
   * allowedRoleIds[] is empty when no resource was selected, or when the selected resource has no allowed roles.
   */
  useEffect(() => {
    const items: IRole[] = data?.items || [];
    setRoles(
      items
        .filter(
          (role) => (!role.locked || showLockedRoles) && (!allowedRoleIds?.length || allowedRoleIds.includes(role.id)),
        )
        .map((role) => ({ label: role.name, value: role[valueIndex], id: role.id })),
    );
  }, [allowedRoleIds, data?.items, valueIndex]);

  // When onChange is called, the selected value and its id is passed to the onChange function
  return (
    <Select
      {...{ mode }}
      allowClear
      value={value}
      className={className}
      showSearch
      loading={loadingRecursive}
      disabled={readOnly || loadingRecursive}
      style={{ width: '100%' }}
      placeholder={!readOnly && 'Search and Select a Role'}
      onChange={(value) => onChange(value, roles.find((role) => role.value === value)?.id || '')}
      options={roles}
      filterOption={(inputValue, option) => {
        const label: string = option?.label as string;
        const labelUp: string = label?.toUpperCase() || '';
        return labelUp?.indexOf(inputValue.toUpperCase()) !== -1;
      }}
    >
      {renderOptions &&
        renderOptions(roles || []).map((item) => (
          <Option key={item.value} value={item.value} disabled={item.disabled || false}>
            {item.label}
          </Option>
        ))}
    </Select>
  );
};
