import { MenuOutlined } from '@ant-design/icons';
import { Drawer, Layout, Result, Select, Space, Spin } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ActiveLayouts, ApplicationsSelect, AvatarMenu, Breadcrumb, MainMenu } from '../components';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl, UserClaims } from '@okta/okta-auth-js';
import { AuthenticationPage } from '../pages';
import config from '../conf';

import usePermissions from 'hooks/usePermissions';
import { usePermissionsStore } from 'store/permissionsStore';

const { Header, Content, Footer, Sider } = Layout;
const { environment } = config.authManager;

export type Platform = { key: string; label: string; value: string; pathname: string };
export default () => {
  const platforms = [
    { key: 'authManager', label: 'Auth Manager', value: 'authManager', pathname: '/' },
    { key: 'import', label: 'Import', value: 'import', pathname: '/import' },
  ];
  const authManagerPlatform = platforms[0];

  const { pathname } = useLocation();
  const selectedOption = localStorage.getItem('application');
  const { applicationId } = useParams();
  const permissionsStored = usePermissionsStore();
  const { md = true } = useBreakpoint();
  const { data: dataPermissions, getPermissions, error } = usePermissions();
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);

  /**
   * Returns the platform based on the provided pathname.
   * It returns the default platform (authManager) when there is a selected appId or the pathname is '/'.
   */
  const getPlatformByPathname = (platforms: Platform[], pathname: string) => {
    const defaultPlatform = platforms[0];
    if (pathname === '/' || applicationId) return defaultPlatform;
    return platforms.reduce<Platform>((matchedPlatform, platform) => {
      if (pathname.startsWith(platform.pathname)) return platform;
      return matchedPlatform;
    }, defaultPlatform);
  };

  const platformByPathname = getPlatformByPathname(platforms, pathname);
  const [platform, setPlatform] = useState<Platform>(platformByPathname);

  const [application, setApplication] = useState(
    selectedOption
      ? JSON.parse(selectedOption)
      : {
          code: '',
          defaultRoleAssignments: [],
          id: '',
          name: '',
          oktaCompatibilityMode: false,
          oktaGroupName: '',
        },
  );
  const [userInfo, setUserInfo] = useState<UserClaims>();
  const contentStyle: CSSProperties = {};
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();
  const [unauthorized, setUnauthorized] = useState(false);
  const errorMessageFallback = 'Something is wrong. Please try again in a few minutes.';

  /**
   * This useEffect updates the platform when the user navigates between different platforms using the Back button
   */
  useEffect(() => {
    if (platformByPathname.key !== platform.key) setPlatform(platformByPathname);
  }, [platformByPathname, platform]);

  useEffect(() => {
    // The if/else by platform is a workaround, we might want to extract each platform logic to a different component
    if (platform.key === 'authManager' && application.value != applicationId) {
      const [_, page] = pathname.substring(1).split('/');

      // regex to identify UUID strings representing the applicationId
      const regex = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/;
      localStorage.setItem('application', JSON.stringify(application));
      if (page?.match(regex)) {
        navigate(`/${application.value}/${application.id}`);
      } else {
        navigate(page ? `/${application.value}/${page}` : `/${application.value}`);
      }
    } else {
      localStorage.setItem('application', '{}');
    }
  }, [application.value, platform.key]);

  useEffect(() => {
    if (!authState) return;

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }

    // User Info
    oktaAuth.token
      .getUserInfo()
      .then((payload) => {
        if (payload) setUserInfo(payload);
      })
      .catch(function (error) {
        console.log('user info error=> ', error);
        setUserInfo(undefined);
      });
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  // Permissions - store
  // Only call function getPermissions if the token was set.
  useEffect(() => {
    if (!dataPermissions && authState?.accessToken?.accessToken) getPermissions();
    permissionsStored.setPermission(dataPermissions);
  }, [dataPermissions, authState?.accessToken?.accessToken]);

  if (!authState || !authState?.isAuthorized || unauthorized) {
    return <AuthenticationPage unnauthorized={unauthorized} />;
  }

  const onError = (error: any) => {
    if (error.code === 'ERR_NETWORK') {
      setUnauthorized(true);
    }
  };

  const onPlatformSelect = (_value: Platform['value'], option: Platform) => {
    localStorage.setItem('application', '{}');
    if (option.key !== platform.key) setPlatform(option);
    navigate(option.pathname);
  };

  return error ? (
    <Result title={error?.data?.error || error?.message || errorMessageFallback} />
  ) : !permissionsStored?.permissions?.length ? (
    <Spin style={{ minHeight: '400px', position: 'relative', left: '50%', top: '160px' }} size='large' />
  ) : (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        className='sider'
        collapsedWidth={50}
        hidden={!md}
        breakpoint='lg'
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
      >
        <MainMenu isCollapsed={collapsed} platform={platform} />
      </Sider>
      <Drawer
        placement={'left'}
        width={200}
        className='drawer-menu'
        closable={true}
        onClose={() => setVisible(false)}
        visible={visible && !md}
        key={'left'}
        style={{ color: '#fff' }}
      >
        {/* This menu is only visible on small screens (burger menu) */}
        {<MainMenu isCollapsed={collapsed} platform={platform} />}
      </Drawer>
      <Layout className='site-layout' style={contentStyle}>
        <Header className='header'>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            {/* TODO: change link */}
            <Link to='/' className='logo'>
              <div className='icon' hidden={!collapsed}>
                <img src={'/dolby_logo.png'} alt='Auth Manager' />
              </div>
            </Link>
            <div className='header-left'>
              <Select
                className='platform-select ant-select-borderless'
                defaultValue={platform.value}
                onSelect={onPlatformSelect}
                options={platforms}
                value={platform.value}
              />
            </div>
          </div>

          <div className='header-right'>
            <Space style={{ display: 'flex' }}>
              <MenuOutlined hidden={md} className='drawer-button' onClick={() => setVisible(true)} />
              {/* The application dropdown is only visible when the selected platform is authManager */}
              {platform.key === authManagerPlatform.key && (
                <>
                  <ApplicationsSelect
                    onChange={(value) => setApplication(value)}
                    value={application}
                    onError={onError}
                  />
                  <span className='txt-white'>|</span>
                </>
              )}
              <AvatarMenu userData={userInfo} />
            </Space>
          </div>
        </Header>
        <Content className='content'>
          <Breadcrumb />
          <div className='site-layout-background'>
            {platform.key !== authManagerPlatform.key || application.value ? (
              <Outlet context={{ application }} />
            ) : (
              <Spin style={{ position: 'absolute', left: '50%', top: '50%' }} size='large' />
            )}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', padding: '16px 50px' }}>
          {environment === 'development' && <ActiveLayouts />}
          {platform.label}
        </Footer>
      </Layout>
    </Layout>
  );
};
