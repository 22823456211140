import { create } from 'zustand';

interface MainMenuStore {
  mainmenu: TMainMenuItem[];
  setMainMenu: (val: TMainMenuItem[]) => void;
  resetMainMenu: () => void;
}
export type TMainMenuItem = {
  icon: any;
  key: string;
  label: any;
  link: string;
  linkLabel?: string;
};

export const useMenuStore = create<MainMenuStore>()((set) => ({
  mainmenu: [],
  setMainMenu: (val: any) =>
    set(() => ({
      mainmenu: val,
    })),
  resetMainMenu: () =>
    set(() => ({
      mainmenu: [],
    })),
}));
