import { Space, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useOktaAuth } from '@okta/okta-react';
import { unnauthorizedMessage } from '../components/OktaSecurity';

const { Title } = Typography;

export default (props: { defaultMessage?: string; unnauthorized?: boolean }) => {
  const { authState } = useOktaAuth();
  const { isAuthenticated, message } = (authState as any) || {
    isAuthenticated: false,
    message: 'Redirecting to login',
  };

  return (
    <div className='authentication-content'>
      <div className='authentication-modal'>
        <div>
          <Title level={3}>Auth Manager</Title>
          <Space size={0}>
            <Title>{props?.unnauthorized === true ? unnauthorizedMessage : props?.defaultMessage || message}</Title>
            <LoadingOutlined hidden={!authState || isAuthenticated} style={{ fontSize: '20px' }} />
          </Space>
        </div>
      </div>
    </div>
  );
};
