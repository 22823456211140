import useAxios from './useAxios';
import { useEffect, useState } from 'react';

export interface IClient {
  assignments: [];
  created: string;
  id: string;
  label: string;
  name: string;
  platformUserId: string;
  status: string;
}

export default (): {
  data: any;
  error: any;
  assignments: any;
  loading: boolean;
  loadingAssignment: boolean;
  getAll: (applicationId: string) => void;
  cancel: () => void;
  get: (applicationId: string, clientId: string) => void;
  set: (applicationId: string, clientId: string, client: IClient) => void;
  getAssignments: (applicationId: string, clientId: string) => void;
  addAssignment: (applicationId: string, clientId: string, assignmentCode: string) => Promise<void>;
  removeAssignment: (applicationId: string, clientId: string, assignmentCode: string) => Promise<void>;
  search: (applicationId: string, query: string, limit?: number) => void;
} => {
  const { data: payload, loading, error, request, requestAsync } = useAxios();
  const {
    data: dataAssignments,
    loading: loadingAssignment,
    error: errorAssignments,
    requestAsync: requestAssignments,
    cancel,
  } = useAxios();
  const [assignments, setAssignments] = useState([]);
  const [data, setData] = useState();

  useEffect(() => {
    payload && setData(payload);
  }, [payload]);

  useEffect(() => {
    dataAssignments && setAssignments(dataAssignments.items || [dataAssignments]);
  }, [dataAssignments]);

  const getAll = (applicationId: string): void => {
    request(`/applications/${applicationId}/clients`);
  };

  const get = (applicationId: string, clientId: string): Promise<any> => {
    return requestAsync(`/applications/${applicationId}/clients`, 'get', {}, (response: any) => {
      const items = (response?.items || []).filter((client: IClient) => client.id === clientId);
      setData({ ...response, items });
    });
  };

  const set = (applicationId: string, clientId: string, resource: IClient) => {
    request(`/applications/${applicationId}/clients/${clientId}`, 'put', resource);
  };
  const getAssignments = async (applicationId: string, clientId: string) => {
    await requestAssignments(`/applications/${applicationId}/clients/${clientId}/assignments`);
  };

  const search = (applicationId: string, query: string, limit = 200) => {
    request(`/applications/${applicationId}/clients?q=${encodeURIComponent(query)}&limit=${limit}`);
  };

  const addAssignment = async (applicationId: string, clientId: string, assignmentCode: string) => {
    return requestAssignments(
      `/applications/${applicationId}/clients/${clientId}/assignments`,
      'post',
      {
        code: assignmentCode,
      },
      (_: any) => getAssignments(applicationId, clientId),
    );
  };

  const removeAssignment = async (applicationId: string, clientId: string, assignmentCode: string) => {
    return requestAssignments(
      `/applications/${applicationId}/clients/${clientId}/assignments/${assignmentCode}`,
      'delete',
      {},
      (_: any) => getAssignments(applicationId, clientId),
    );
  };

  return {
    data,
    assignments,
    error: error || errorAssignments,
    loading,
    loadingAssignment,
    addAssignment,
    getAll,
    get,
    set,
    getAssignments,
    removeAssignment,
    search,
    cancel,
  };
};
