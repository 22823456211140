import generalContent from '../conf/generalContent.json';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';
export default ({ title }: { title: string }): LabelTooltipType => ({
  title: (
    <>
      {title}{' '}
      <a href={generalContent.general.linkDocumentation} target='_blank' rel='noreferrer'>
        Learn more.
      </a>
    </>
  ),
  overlayInnerStyle: { textAlign: 'center' },
});
