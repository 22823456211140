import { ContainerOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import InviteCodesTable from 'components/InviteCodesTable';
import useInviteCodes, { IInviteCodeItem } from 'hooks/useInviteCodes';
import useLocalStorage from 'hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Page } from '../components';
import generalContent from '../conf/generalContent.json';
import { setExpiredDate } from 'utils/utils.js';
import usePermissions from 'hooks/usePermissions';

export default () => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const [inviteCodes, setInviteCodes] = useState<IInviteCodeItem[]>([]);
  const [total, setTotal] = useState(0);
  const { data, loading, error, getAllWithLimit } = useInviteCodes();
  const { checkPermissions } = usePermissions();
  const [lsPageSize] = useLocalStorage<number>('am-page-size', 10);

  useEffect(() => {
    getAllWithLimit(applicationId || '', lsPageSize, 0, 'value asc');
    setInviteCodes([]);
  }, [applicationId]);

  useEffect(() => {
    const items = data?.items;

    items?.map((el: IInviteCodeItem) => {
      // Expired Date
      if (el?.constraints?.expiration) {
        el['expired'] = setExpiredDate(el.constraints.expiration);
      } else {
        el['expired'] = undefined;
      }

      // Exhausted
      const usage = el.constraints?.usageLimit;
      const redemptions = el.numberOfRedemptions;
      if (usage !== undefined && redemptions !== undefined) {
        el['exhausted'] = redemptions >= usage;
      } else {
        el['exhausted'] = undefined;
      }
    });

    setInviteCodes(items || []);
    setTotal(data?.total || 0);
  }, [data, data?.items]);

  const onPageChange = (val: number, pageSize: number, sortPage: string) => {
    getAllWithLimit(applicationId || '', pageSize, (val - 1) * pageSize, sortPage);
  };

  const onSortChange = (sort: string) => {
    getAllWithLimit(applicationId || '', lsPageSize, 0, sort);
  };

  const buttons = checkPermissions(applicationId, 'inviteCode:create') && (
    <Button type='primary'>
      <Link to={'create'}>Add Invite</Link>
    </Button>
  );

  return (
    <Page
      title={'Invite Codes'}
      titleIcon={<ContainerOutlined />}
      description={generalContent.pages.inviteCodes.pageDescription}
      className='inviteCodesPage'
      extra={error ? undefined : buttons}
    >
      {error ? (
        <Result title={error?.data?.error || 'Something is wrong. Please try again in a few minutes.'} />
      ) : (
        <InviteCodesTable
          data={inviteCodes}
          total={total}
          loading={loading}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
        ></InviteCodesTable>
      )}
    </Page>
  );
};
