import { Button, Select, SelectProps, Spin } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import useUsers, { IUser } from '../hooks/useUsers';

// Usage of DebounceSelect
export interface UserValue {
  label: string;
  value: string;
}

export interface SearchUsersSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string, isFeching: boolean) => Promise<ValueType[]>;
  onAssign: (users: ValueType[]) => void;
}

export const SearchUsersSelect = <
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({
  fetchOptions,
  onAssign,
  ...props
}: SearchUsersSelectProps<ValueType>) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value, fetching).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return loadOptions;
  }, [fetchOptions]);

  const handleAssign = () => {
    onAssign(props.value as any);
  };

  return (
    <div className='search-users-select'>
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size='small' /> : null}
        {...props}
        options={options}
      />
      <Button type={'primary'} className='search-user-assign-button' onClick={() => handleAssign()}>
        Assign
      </Button>
    </div>
  );
};

export default (props: {
  applicationId: string;
  onAssign: (users: UserValue[], setValue: (users: UserValue[]) => void) => void;
  mapOption: (user: IUser) => UserValue;
  userType?: string;
}) => {
  const [value, setValue] = useState<UserValue[]>([]);
  const { searchUsers } = useUsers();

  const fetchUserList = (username: string): Promise<UserValue[]> => {
    return new Promise((resolve) => {
      searchUsers(
        props.applicationId,
        username,
        (data: any) => {
          const users = (data?.items || []).map(props.mapOption);
          resolve(users);
        },
        props.userType,
      ).then(() => []);
    });
  };

  return (
    <SearchUsersSelect
      mode='multiple'
      onAssign={(users: UserValue[]) => {
        props.onAssign(users, setValue);
      }}
      value={value}
      placeholder='Select users'
      fetchOptions={fetchUserList}
      style={{ width: '400px' }}
      onChange={(newValue) => {
        setValue(newValue as UserValue[]);
      }}
    />
  );
};
